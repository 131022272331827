import React from 'react'
import styles from './ShopProgress.module.css'
import icLocation from '../../../images/ic_location.svg'
import icAccount from '../../../images/ic_account.svg'
import icCheck from '../../../images/ic_check.svg'
import icCard from '../../../images/ic_card.svg'
import { useNavigate, useLocation } from 'react-router-dom'

const steps = ['account', 'shipping', 'payment', 'review']

interface ISignupProgressProps {
  className?: string
  hideAccountForm?: boolean
}

function ShopProgress(props: ISignupProgressProps) {
  const navigate = useNavigate()
  const location = useLocation()
  const step = location.pathname.replaceAll(props.hideAccountForm ? "/buy/" : "/signup/", "")
  // const site = useStaticQuery(
  //   graphql`
  //     query {
  //       ...CheckoutPageFragment
  //     }
  //   `
  // )

  const getStyle = (stepToCheck: string) => {
    return steps.indexOf(step) >= steps.indexOf(stepToCheck) ? styles.enabledCircle : styles.disabledCircle
  }

  const getLabelStyle = (stepToCheck: string) => {
    return steps.indexOf(step) >= steps.indexOf(stepToCheck) ? '' : styles.disabledLabel
  }

  function changeStep(stepToChange: string) {
    if (steps.indexOf(step) >= steps.indexOf(stepToChange)) {
      navigate(`${stepToChange}${location.search}`)
    }
  }

  return (
    <section className={props.className}>
      <div className={styles.container}>
        {
          props.hideAccountForm !== true
          &&
          <div className={styles.listItem} onClick={() => changeStep('account')}>
            <div className={styles.invisibleSpacer} />
            <div className={styles.centerCircle}>
              <span className={`${styles.circle} ${getStyle('account')}`}>
                <img src={icAccount} alt="account" />
              </span>
            </div>
            <div className={styles.spacer} />
          </div>
        }
        <div className={styles.listItem} onClick={() => changeStep('shipping')}>
          {
            props.hideAccountForm !== true
            &&
            <div className={styles.spacer} />
          }
          {
            props.hideAccountForm === true
            &&
            <div className={styles.invisibleSpacer} />
          }
          <div className={styles.centerCircle}>
            <span className={`${styles.circle} ${getStyle('shipping')}`}>
              <img src={icLocation} alt="shipping" />
            </span>
          </div>
          <div className={styles.spacer} />
        </div>
        <div className={styles.listItem} onClick={() => changeStep('payment')}>
          <div className={styles.spacer} />
          <div className={styles.centerCircle}>
            <span className={`${styles.circle} ${getStyle('payment')}`}>
              <img src={icCard} alt="payment" />
            </span>
          </div>
          <div className={styles.spacer} />
        </div>
        <div className={styles.listItem} onClick={() => changeStep('review')}>
          <div className={styles.spacer} />
          <div className={styles.centerCircle}>
            <span className={`${styles.circle} ${getStyle('review')}`}>
              <img src={icCheck} alt="review" />
            </span>
          </div>
          <div className={styles.invisibleSpacer} />
        </div>
      </div>
      <div className={styles.labelsContainer}>
        {
          props.hideAccountForm !== true
          &&
          <div className={styles.listItem}>
            <div className={styles.centerCircle}>
              <label className={getLabelStyle('account')}>Account</label>
            </div>
          </div>
        }
        <div className={styles.listItem}>
          <div className={styles.centerCircle}>
            <label className={getLabelStyle('shipping')}>Shipping</label>
          </div>
        </div>
        <div className={styles.listItem}>
          <div className={styles.centerCircle}>
            <label className={getLabelStyle('payment')}>Payment</label>
          </div>
        </div>
        <div className={styles.listItem}>
          <div className={styles.centerCircle}>
            <label className={getLabelStyle('review')}>Review</label>
          </div>
        </div>
      </div>
    </section >
  )
}

export default ShopProgress

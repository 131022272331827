import React, { useEffect } from 'react'
import styles from './signup.module.css'
import SignupProgress from '../components/shop/signupProgress'
import AccountDataForm from '../components/shop/accountDataForm'
import CartSummary from '../components/shop/cartSummary'
import { SingupStore } from '../store/SignupStore'
import ShippingForm from '../components/shop/shippingForm'
import PaymentForm from '../components/shop/paymentForm'
import ReviewOrder from '../components/shop/reviewOrder'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import { Navbar } from '../components/common/navbar/navbar'
import { Route, Routes, useLocation } from 'react-router-dom'
import { usePixel } from '../utils/hooks'

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY!);

function Signup() {
  return (
    <Elements
      stripe={stripePromise}
      options={{
        fonts: [{
          family: 'GT Walsheim',
          src: 'url(https://qvin.com/static/GT-Walsheim-Pro-Regular-526a7baeaf4ec1259031548024c4dbb7.otf)',
          weight: '500',
        },]
      }}
    >
      <SingupStore>
        <div className="w-full h-full font-walsheim">
          <Navbar />
          <div className={`flex flex-col relative justify-center ${styles.content}`}>
            <SignupContent />
          </div>
        </div>
      </SingupStore>
    </Elements >
  )
}

function SignupContent() {
  const track = usePixel()
  const location = useLocation()
  const step = location.pathname.replaceAll("/signup/", "")

  useEffect(() => {
    if (step === "account") {
      track('NewAccount')
    } else if (step === "shipping") {
      track('ShippingInfo')
    } else if (step === "payment") {
      track('InitiateCheckout')
    } else if (step === "review") {
      track('ConfirmCheckout')
    }
  }, [step]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      <div style={{ marginTop: "5rem" }}></div>
      <SignupProgress className="mx-0 sm:mx-32 lg:mx-64" />
      <div className="my-8 flex flex-col-reverse sm:flex-row mx-0 sm:mx-16 lg:mx-32">
        <div className="flex-1 sm:mr-4">
          <div className="flex-1">
            <Routes>
              <Route path="/account" element={<AccountDataForm />} />
              <Route path="/shipping" element={<ShippingForm />} />
              <Route path="/review" element={<ReviewOrder />} />
            </Routes>
          </div>
          {
            <div style={{
              // card data is saved in Stripe elements
              // we can't restore state because we don't have data
              // so always render this view to mantain state
              // just swtich display attribute for visibility
              display: step === 'payment' ? 'inline' : 'none'
            }}>
              <PaymentForm />
            </div>
          }
        </div>
        <div className={`flex-1 ${step === 'shipping' || step === 'review' ? '' : 'hidden'} sm:block sm:ml-4`}>
          <CartSummary className="my-4" step={step} />
        </div>
      </div>
    </div>
  )
}

export default Signup
import { useCallback, useEffect, useRef, useState, useLayoutEffect } from 'react'
import { UserService } from '../service/UserService'
import { User } from '../types/user'

export function useIsMounted() {
  const isMountedRef = useRef(true)
  const isMounted = useCallback(() => isMountedRef.current, [])

  useEffect(() => {
    return () => void (isMountedRef.current = false)
  }, [])

  return isMounted
}

export function useCurrentUser(): User | undefined {
  const [user, setUser] = useState<User | undefined>(undefined)

  async function loadUser() {
    const storedUser = UserService.instance().getCurrentUser()
    setUser(storedUser)
  }

  useEffect(() => {
    if (!user) {
      loadUser()
    }
  }, [user?.id]) // eslint-disable-line react-hooks/exhaustive-deps

  return user
}


type FbTrackFunction = (type: string, event: string, payload?: Record<string, unknown>) => void

/** Helper hook used to initialize and send events to
 * Facebook Pixel
 */
export function usePixel() {
  const pixel = useRef<FbTrackFunction>()

  useLayoutEffect(() => {
    if (!pixel.current && typeof window !== 'undefined') {
      // @ts-ignore
      pixel.current = window.fbq
    }
  }, [])

  const track = (event: string, payload?: Record<string, unknown>) => {
    if (pixel.current) {
      pixel.current('trackCustom', event, payload)
    }

    if ((window as any).dataLayer) {
      const dl = (window as any).dataLayer as any
      dl.push({ event: event })
    }
  }

  return track
}

import React, { useEffect, useState } from 'react'
import { Navbar } from '../components/common/navbar/navbar'
import styles from './consent.module.css'
import { useLocation } from 'react-router'
import { ConsentForm } from '../types/user'
import { ConsentService } from '../service/ConsentService'
import ReactMarkdown from 'react-markdown'
import { Helmet } from 'react-helmet';

export function Consent() {
  const location = useLocation()
  const consentName = location.pathname.replace('/consent/', '')
  const version = new URLSearchParams(window.location.search).get('v')

  const [consentForm, setConsentForm] = useState<ConsentForm | undefined>(undefined)
  const [fetchFailed, setFetchFailed] = useState<boolean>(false)

  var metaDescription: string | null = null

  if (consentName.toLocaleLowerCase() === 'terms-of-use') {
    metaDescription = 'These Terms of Service are a legally binding agreement between you and Qvin'
  } else if (consentName.toLocaleLowerCase() === 'privacy-policy') {
    metaDescription = 'Qvin respects your privacy and is committed to protecting it'
  }

  useEffect(() => {
    ConsentService.instance().getConsentByNameAndVersion(consentName, version).then((consentForm) => {
      setConsentForm(consentForm)
    }).catch(() => {
      setFetchFailed(true)
    })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <section className={styles.container}>
      <Helmet>
        <title>{`${consentForm?.name}  - Qvin`}</title>
        {
          metaDescription
          &&
          <meta name="description" content={metaDescription} />
        }
      </Helmet>
      <Navbar />
      <section className={fetchFailed ? styles.bodyCenter : styles.body}>
        {
          fetchFailed
          &&
          <p className={styles.error}>Error loading consent. Please try again</p>
        }
        {
          consentForm
          &&
          <ReactMarkdown className="prose py-8">
            {consentForm.version.content}
          </ReactMarkdown>
        }
      </section>
    </section>
  )
}
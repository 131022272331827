export function buildHeaders() {
  return {
    "Accept": "application/json;charset=utf-8",
    "Content-Type": "application/json",
  };
}

export function buildAuthHeaders() {
  const token = window.localStorage.getItem("accessToken");
  if (!token) {
    return buildHeaders();
  }

  return {
    ...buildHeaders(),
    Authorization: `Bearer ${token}`,
  };
}

import axios from 'axios'
import { NetworkError } from '../error/NetworkError';
import { ErrorCode, RequestError } from '../error/RequestError';
import { Address, AddressValidationResult, ConsentData, ConsentForm, OAuth2AccessToken, User, VerifyChannel } from '../types/user';
import { buildAuthHeaders, buildHeaders } from '../utils/api';
import { SECURE_API_ENDPOINT } from '../utils/constants';

export class UserService {
  public static instance(): UserService {
    if (UserService._instance == null) {
      UserService._instance = new UserService();
    }
    return UserService._instance;
  }

  private static _instance: UserService;

  private constructor() { }

  public async login(email: string, password: string, code2fa: string, channel: VerifyChannel): Promise<OAuth2AccessToken> {
    try {
      const params = new URLSearchParams()
      params.append('username', email)
      params.append('password', password)
      params.append('grant_type', 'password')
      params.append('2fa_code', code2fa)
      params.append('verify_channel', channel.toString())

      const response = await axios.post<OAuth2AccessToken>(`${SECURE_API_ENDPOINT}/account_api/oauth/token`,
        params, {
        headers: {
          ...buildHeaders(),
          'Authorization': 'Basic YnJvd3Nlcjpicm93c2Vyc2VjcmV0',
          'Content-Type': 'application/x-www-form-urlencoded'
        },
      })

      window.localStorage.setItem('accessToken', response.data.access_token)
      const user = await this.getUserById(response.data.userId)
      window.localStorage.setItem('user', JSON.stringify(user))

      return response.data
    } catch (e: any) {
      if (e instanceof NetworkError) {
        if (e?.body?.error_description === 'failed_verify_authentication') {
          throw new RequestError(ErrorCode.errorVerifying2faCode);
        } else if (e?.body?.error_description === '2fa_code_not_valid') {
          throw new RequestError(ErrorCode.error2faCodeNotValid);
        } else if (e?.body?.error_description === 'missing_phone_number') {
          throw new RequestError(ErrorCode.missingPhoneNumberForLogin);
        } else if (e?.body?.error_description === '2fa_code_verify_failed') {
          throw new RequestError(ErrorCode.errorVerifying2faCode);
        }
        throw new RequestError(ErrorCode.failLogin);
      }

      throw e
    }
  }

  public logout() {
    window.localStorage.removeItem('accessToken')
    window.localStorage.removeItem('user')
  }

  public async requestVerificationCode(email: string, password: string, channel: VerifyChannel): Promise<string> {
    try {
      const response = await axios.post<string>(`${SECURE_API_ENDPOINT}/account_api/requestVerificationCode`,
        {
          email,
          password,
          channel,
        },
        {
          headers: buildHeaders(),
        })

      return response.data
    } catch (e: any) {
      if (e instanceof NetworkError) {
        if (e?.body?.message === "missing_phone_number") {
          throw new RequestError(ErrorCode.missingPhoneNumberForLogin);
        } else if (e?.body?.message === "2fa_code_request_failed") {
          throw new RequestError(ErrorCode.errorRequesting2faCode);
        } else if (e?.body?.message?.includes("Could not find user")) {
          throw new RequestError(ErrorCode.wrongEmailOrPassword);
        } else if (e?.body?.message?.includes("Too many login failure,")) {
          throw new RequestError(ErrorCode.tooManyLoginAttempts);
        }

        throw new RequestError(ErrorCode.unknown)
      }

      throw e
    }
  }

  public async requestVerificationCodeTo(to: string, channel: VerifyChannel): Promise<void> {
    try {
      await axios.post<string>(`${SECURE_API_ENDPOINT}/communication_api/requestVerificationCode/${to}`, null, {
        headers: buildHeaders(),
        params: {
          channel,
        }
      })
    } catch (e: any) {
      if (e instanceof NetworkError) {
        if (e?.body?.message === "2fa_code_request_failed") {
          throw new RequestError(ErrorCode.errorRequesting2faCode);
        }
        throw new RequestError(ErrorCode.unknown);
      }

      throw e
    }
  }

  public async loginAndAddPhoneNumber(email: string, password: string, phone: string, verifyCode: string): Promise<void> {
    try {
      const response = await axios.post<OAuth2AccessToken>(`${SECURE_API_ENDPOINT}/account_api/loginAndAddPhoneNumber`, {
        phone,
        isPrimaryNumber: true,
      }, {
        headers: {
          ...buildHeaders(),
          'Authorization': 'Basic YnJvd3Nlcjpicm93c2Vyc2VjcmV0',
        },
        params: {
          email,
          password,
          verifyCode,
          scope: 'browser',
        }
      })

      window.localStorage.setItem('accessToken', response.data.access_token)
      const user = await this.getUserById(response.data.userId)
      window.localStorage.setItem('user', JSON.stringify(user))
    } catch (e: any) {
      if (e instanceof NetworkError) {
        if (e?.body?.message === "2fa_code_verify_failed") {
          throw new RequestError(ErrorCode.verifyTwoFactorFailed);
        } else if (e?.body?.message === "2fa_code_not_valid") {
          throw new RequestError(ErrorCode.invalidTwoFactorCode);
        } else if (e?.body?.message === "invalid_credentials") {
          throw new RequestError(ErrorCode.wrongEmailOrPassword);
        } else if (e?.body?.message === "missing_phone_number" || e?.body?.message === "invalid_phone_number") {
          throw new RequestError(ErrorCode.invalidPhoneNumber);
        } else if (e?.body?.message === "existing_phone_number") {
          throw new RequestError(ErrorCode.phoneNumberAlreadyUsed);
        } else if (e?.body?.message === "missing_verification_code") {
          throw new RequestError(ErrorCode.missingVerificationCode);
        }

        throw new RequestError(ErrorCode.unknown);
      }

      throw e
    }
  }

  public getCurrentUser(): User | undefined {
    const userJson = window.localStorage.getItem('user')
    if (!userJson) {
      return undefined
    }
    return JSON.parse(userJson) as User
  }

  public async register(data: {
    firstName: string, lastName: string, email: string, password: string,
    addressLine: string, city: string, state: string | undefined, zip: string, country: string
  }): Promise<OAuth2AccessToken> {
    try {
      const response = await axios.post<OAuth2AccessToken>(`${SECURE_API_ENDPOINT}/account_api/registration`, null, {
        headers: buildHeaders(),
        params: {
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
          password: data.password,
          addressLine: data.addressLine,
          city: data.city,
          state: data.state,
          zip: data.zip,
          country: data.country,
          scope: 'browser',
        }
      })
      if (response.status !== 200) {
        throw new RequestError(ErrorCode.failedCreateAccount);
      }

      window.localStorage.setItem('accessToken', response.data.access_token)
      const user = await this.getUserById(response.data.userId)
      window.localStorage.setItem('user', JSON.stringify(user))

      return response.data
    } catch (e: any) {
      if (e instanceof NetworkError) {
        if (e?.body?.message === "email_already_exists") {
          throw new RequestError(ErrorCode.emailAlreadyExists);
        }

        throw new RequestError(ErrorCode.failedCreateAccount);
      }

      throw e
    }
  }

  public async validateEmail(email: string | undefined): Promise<void> {
    if (!email) {
      throw new RequestError(ErrorCode.invalidEmailFormat);
    }

    try {
      await axios.get(`${SECURE_API_ENDPOINT}/account_api/registration/${email}/validate`, {
        headers: buildHeaders(),
      })
    } catch (e: any) {
      if (e instanceof NetworkError) {
        if (e?.body?.message === "email_already_exists") {
          throw new RequestError(ErrorCode.emailAlreadyExists);
        } else if (e?.body?.message === "email_format_invalid") {
          throw new RequestError(ErrorCode.invalidEmailFormat);
        }
        throw new RequestError(ErrorCode.invalidEmailFormat);
      }

      throw e
    }
  }

  private async getUserById(userId: string): Promise<User> {
    try {
      const response = await axios.get<User>(`${SECURE_API_ENDPOINT}/account_api/user/${userId}`, {
        headers: buildAuthHeaders(),
      })
      return response.data
    } catch (e: any) {
      if (e instanceof NetworkError) {
        throw new RequestError(ErrorCode.unknown);
      }

      throw e
    }
  }

  public async isPhoneUsed(phone: string): Promise<boolean> {
    try {
      const response = await axios.get<boolean>(`${SECURE_API_ENDPOINT}/account_api/registration/phoneNumber/${phone}/exists`, {
        headers: buildHeaders(),
      })
      return response.data
    } catch (e: any) {
      if (e instanceof NetworkError) {
        throw new RequestError(ErrorCode.unknown);
      }

      throw e
    }
  }

  public async validateAddress(address: Address): Promise<AddressValidationResult> {
    try {
      const response = await axios.post<AddressValidationResult>(`${SECURE_API_ENDPOINT}/account_api/address/validate`, address, {
        headers: buildHeaders(),
      })
      return response.data
    } catch (e: any) {
      if (e instanceof NetworkError) {
        throw new RequestError(ErrorCode.unknown);
      }

      throw e
    }
  }

  public async saveAddress(address: Address): Promise<Address> {
    try {
      const response = await axios.post<Address>(`${SECURE_API_ENDPOINT}/account_api/address`, address, {
        headers: buildAuthHeaders(),
      })
      return response.data
    } catch (e: any) {
      if (e instanceof NetworkError) {
        throw new RequestError(ErrorCode.unknown);
      }

      throw e
    }
  }

  public async getConsentsNeedingUpdate(userId: string): Promise<Array<ConsentForm>> {
    try {
      const response = await axios.get<Array<ConsentForm>>(`${SECURE_API_ENDPOINT}/account_api/user/${userId}/hasLatestConsents`, {
        headers: buildAuthHeaders(),
      })
      return response.data
    } catch (e: any) {
      if (e instanceof NetworkError) {
        throw new RequestError(ErrorCode.unknown);
      }

      throw e
    }
  }

  public async saveCustomerConsents(consentData: ConsentData): Promise<string> {
    try {
      const response = await axios.post<string>(`${SECURE_API_ENDPOINT}/account_api/user/${consentData.customerId}/consents`, consentData, {
        headers: buildHeaders(),
      })

      return response.data
    } catch (e: any) {
      if (e instanceof NetworkError) {
        if (e?.body?.message === "missing_phone_number") {
          throw new RequestError(ErrorCode.missingPhoneNumberForLogin);
        } else if (e?.body?.message === "2fa_code_request_failed") {
          throw new RequestError(ErrorCode.errorRequesting2faCode);
        } else if (e?.body?.message?.includes("Could not find user")) {
          throw new RequestError(ErrorCode.wrongEmailOrPassword);
        } else if (e?.body?.message?.includes("Too many login failure,")) {
          throw new RequestError(ErrorCode.tooManyLoginAttempts);
        }

        throw new RequestError(ErrorCode.unknown);
      }

      throw e
    }
  }
}
import React, { useState } from 'react'
import '../../../utils/utils.css'
import styles from './AccountDataForm.module.css'
import Button from '../../common/button'
import { useSignupStore } from '../../../store/SignupStore'
import Input from '../../common/input'
import { UserService } from '../../../service/UserService'
import { RequestError } from '../../../error/RequestError'
import { getMessageForError } from '../../../utils/strings'
import { EMAIL_REGEX } from '../../../utils/constants'
import { useNavigate, useLocation } from 'react-router'
import { ShopService } from '../../../service/ShopService'

const passwordSymbolsRegex = new RegExp('[!"#$%&\'()*+,-./:;<=>?@\\[\\]\\\\^_`{|}~]')
const passwordUppercaseRegex = new RegExp('[A-Z]')

function AccountDataForm() {
  const {
    cartId,
    subscriptionId,
    firstName,
    lastName,
    email,
    password,
    shippingAddress,
    setFirstName,
    setLastName,
    setEmail,
    setPassword,
    setShippingAddress,
    setSubscriptionId,
  } = useSignupStore()

  const productId = new URLSearchParams(window.location.search).get('productId')
  const priceId = new URLSearchParams(window.location.search).get('priceId')
  const location = useLocation()
  const navigate = useNavigate()
  const [error, setError] = useState<string | undefined>('')
  const [firstNameError, setFirstNameError] = useState<string | undefined>(undefined)
  const [lastNameError, setLastNameError] = useState<string | undefined>(undefined)
  const [emailError, setEmailError] = useState<string | undefined>(undefined)
  const [passwordError, setPasswordError] = useState<string | undefined>(undefined)
  const [confirmError, setConfirmError] = useState<string | undefined>(undefined)
  const [confirm, setConfirm] = useState<string | undefined>(undefined)
  const [loading, setLoading] = useState<boolean>(false)

  const passwordLengthOk = password && password.length >= 6 && password.length <= 64
  const passwordHasSymbol = password && passwordSymbolsRegex.test(password)
  const passwordHasUppercase = password && passwordUppercaseRegex.test(password)

  const hasErrors = firstNameError !== undefined || lastNameError !== undefined || emailError !== undefined || passwordError !== undefined || confirmError !== undefined
  const continueDisabled = (hasErrors || !firstName || !lastName || !password || !confirm || !email)

  async function goToNextStep() {
    if (continueDisabled || firstNameError ||
      lastNameError ||
      emailError ||
      passwordError ||
      confirmError) {
      return
    }

    setLoading(true)
    setError('')

    try {
      await UserService.instance().validateEmail(email)
    } catch (e) {
      setLoading(false)
      if (e instanceof RequestError) {
        setEmailError(getMessageForError(e.code))
      } else {
        setEmailError('Failed to verify email address. Please try again.')
      }
      return;
    }

    try {
      let addressId: string | undefined = undefined
      const address = { ...shippingAddress, firstName, lastName, email, state: undefined, country: undefined }
      if (cartId && !priceId) {
        addressId = await ShopService.instance().addShipmentInfoToCart(cartId, address)
      } else if (priceId) {
        const sub = await ShopService.instance().saveSubscription(priceId, {
          id: subscriptionId,
          productTypeId: productId!!,
          shippingAddress: address,
        })
        addressId = sub.shippingAddress.id
        setSubscriptionId(sub.id!!)
      }
      setLoading(false)
      setShippingAddress({ ...shippingAddress, firstName, lastName, email, id: addressId })
      navigate(`../shipping${location.search}`)
    } catch (e) {
      setLoading(false)

      if (e instanceof RequestError) {
        setError(getMessageForError(e.code))
      } else {
        setError('Failed to verify email address. Please try again.')
      }
    }
  }

  return (
    <div className="flex-col text-left" tabIndex={0} style={{ outline: "none" }} onKeyUp={(e) => {
      if (e.key === "Enter") {
        goToNextStep()
      }
    }}>
      <p className="text-3xl m-0">Checkout</p>
      <p className="mt-8 mb-2">Create your account</p>
      <div className="flex flex-col sm:flex-row">
        <Input
          id="input_text_FirstName"
          className="sm:mr-4 flex-1"
          type="text"
          name="First name"
          placeholder="First name"
          required
          value={firstName || ''}
          error={firstNameError}
          onChange={value => {
            setFirstName(value)

            if (!value || value.length < 2) {
              setFirstNameError('Field must be at least 2 letters')
            } else {
              setFirstNameError(undefined)
            }
          }} />
        <Input
          id="input_text_LastName"
          className="mt-4 sm:mt-0 flex-1"
          type="text"
          name="Last name"
          placeholder="Last name"
          required
          value={lastName || ''}
          error={lastNameError}
          onChange={value => {
            setLastName(value)

            if (!value || value.length < 2) {
              setLastNameError('Field must be at least 2 letters')
            } else {
              setLastNameError(undefined)
            }
          }} />
      </div>
      <Input
        id="input_text_EmailAddress"
        className="mt-4"
        type="text"
        name="Email address"
        placeholder="Email address"
        required
        value={email || ''}
        error={emailError}
        onChange={value => {
          setEmail(value)

          const isEmailValid = value && EMAIL_REGEX.test(value)
          if (!isEmailValid) {
            setEmailError('Email has invalid format')
          } else {
            setEmailError(undefined)
          }
        }} />
      <p className="mt-8 mb-2">Create your password</p>
      <Input
        id="input_text_Password"
        type="password"
        name="Password"
        placeholder="Password"
        required
        value={password || ''}
        error={passwordError}
        onChange={value => {
          setPassword(value)

          const valueLengthOk = value && value.length >= 6 && value.length <= 64
          const valueHasSymbol = value && passwordSymbolsRegex.test(value)
          const valueHasUppercase = value && passwordUppercaseRegex.test(value)

          if (!valueLengthOk || !valueHasSymbol || !valueHasUppercase) {
            setPasswordError('Password does not meet requirements')
          } else {
            setPasswordError(undefined)
          }
        }} />
      <Input
        id="input_text_PasswordConfirmation"
        className="mt-4"
        type="password"
        name="Password Confirmation"
        placeholder="Password Confirmation"
        required
        value={confirm || ''}
        error={confirmError}
        onChange={value => {
          setConfirm(value)

          if (password !== value) {
            setConfirmError('Passwords do not match')
          } else {
            setConfirmError(undefined)
          }
        }} />
      <p className="mt-8 mb-2 text-sm">Password must include:</p>
      <ul className="p-0">
        <li className={`m-0 text-sm list-none ${passwordLengthOk ? styles.valid : styles.invalid}`}>{passwordLengthOk ? '✓' : '✕'} Between 6 and 64 letters</li>
        <li className={`m-0 text-sm list-none ${passwordHasUppercase ? styles.valid : styles.invalid}`}>{passwordHasUppercase ? '✓' : '✕'} At least one capital letter</li>
        <li className={`m-0 text-sm list-none ${passwordHasSymbol ? styles.valid : styles.invalid}`}>{passwordHasSymbol ? '✓' : '✕'} At least one special character</li>
      </ul>
      <p className="errorMsg text-sm mt-4">{error}</p>
      <Button
        id="button_accountDataContinue"
        className="mt-4"
        isLoading={loading}
        disabled={continueDisabled}
        onClick={goToNextStep}>Continue</Button>
    </div >
  )
}

export default AccountDataForm
import React, { useContext, useState } from 'react'
import { VerifyChannel } from '../types/user'

const SigninContext = React.createContext(null)

export interface ISigninState {
  email?: string
  password?: string
  target?: string
  channel: VerifyChannel
  code?: string
  isPhoneMissing: boolean
}

export interface ISigninStore extends ISigninState {
  setEmail: (email: string) => void
  setPassword: (password: string) => void
  setChannel: (channel: VerifyChannel) => void
  setCode: (code: string) => void
  setTarget: (target: string | undefined) => void
  setIsPhoneMissing: (isPhoneMissing: boolean) => void
}

export function SigninStore(props: any) {
  const [state, setState] = useState<ISigninState>({
    email: undefined,
    password: undefined,
    target: undefined,
    channel: VerifyChannel.SMS,
    code: undefined,
    isPhoneMissing: false,
  })

  //@ts-ignore
  return <SigninContext.Provider value={[state, setState]}>
    {props.children}
  </SigninContext.Provider>
}

export function useSigninStore(): ISigninStore {
  //@ts-ignore
  const [state, setState] = useContext<[ISigninState, any]>(SigninContext)

  function setEmail(email: string) {
    setState((s: ISigninState) => { return { ...s, email } })
  }

  function setPassword(password: string) {
    setState((s: ISigninState) => { return { ...s, password } })
  }

  function setChannel(channel: string) {
    setState((s: ISigninState) => { return { ...s, channel } })
  }

  function setCode(code: string) {
    setState((s: ISigninState) => { return { ...s, code } })
  }

  function setTarget(target: string | undefined) {
    setState((s: ISigninState) => { return { ...s, target } })
  }

  function setIsPhoneMissing(isPhoneMissing: boolean) {
    setState((s: ISigninState) => { return { ...s, isPhoneMissing } })
  }

  return {
    email: state.email,
    password: state.password,
    channel: state.channel,
    code: state.code,
    target: state.target,
    isPhoneMissing: state.isPhoneMissing,
    setEmail,
    setPassword,
    setChannel,
    setCode,
    setTarget,
    setIsPhoneMissing,
  }
}
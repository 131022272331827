export class NetworkError extends Error {
    public statusCode: number;
    public body: any;

    public constructor(statusCode: number, body: any) {
        super();
        this.statusCode = statusCode;
        this.body = body;
    }
}

import React, { useContext, useState } from 'react'
import { ShopService } from '../service/ShopService'
import { UserService } from '../service/UserService'
import { CheckoutItem, OrderAddress, PaymentIntent, PaymentMethod } from '../types/shop'
import { ProductPurchaseItem } from '../types/shop'
import { getLocalStoreItem } from '../utils/windowUtils'

const SignupContext = React.createContext(null)

export interface ISignupState {
  firstName?: string
  lastName?: string
  email?: string
  password?: string
  shippingAddress: OrderAddress
  billingAddress: OrderAddress
  paymentMethod?: PaymentMethod
  accountWasCreated: boolean
  cartId?: string
  cartItems: Array<ProductPurchaseItem>
  paymentIntent?: PaymentIntent
  rememberPaymentMethod: boolean
  reuseShippingForBilling: boolean
  subscriptionId?: string
}

export interface ISignupStore extends ISignupState {
  setFirstName: (firstName: string) => void
  setLastName: (lastName: string) => void
  setEmail: (email: string | undefined) => void
  setPassword: (password: string) => void
  setShippingAddress: (address: OrderAddress) => void
  setBillingAddress: (address?: OrderAddress) => void
  setPaymentMethod: (paymentMethod?: PaymentMethod) => void
  setAccountWasCreated: (value: boolean) => void
  addToCart: (item: CheckoutItem) => Promise<void>
  setRememberPaymentMethod: (rememberPaymentMethod: boolean) => void
  setReuseShippingForBilling: (reuseShippingForBilling: boolean) => void
  setSubscriptionId: (subscriptionId: string) => void
}

export function SingupStore(props: any) {
  const [state, setState] = useState<ISignupState>({
    firstName: undefined,
    lastName: undefined,
    email: undefined,
    password: undefined,
    shippingAddress: {
      legalEntityId: undefined,
      validationMessage: undefined,
      skipExternalValidation: undefined,
      id: undefined,
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
      addressLine: '',
      secondaryAddressLine: undefined,
      city: '',
      zip: '',
      country: 'US',
      state: 'AK',
      type: 'SHIPPING',
      externalId: '',
      isPrimary: false,
    },
    billingAddress: {
      legalEntityId: undefined,
      validationMessage: undefined,
      skipExternalValidation: undefined,
      id: undefined,
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
      addressLine: '',
      secondaryAddressLine: undefined,
      city: '',
      zip: '',
      country: 'US',
      state: 'AK',
      type: 'BILLING',
      externalId: '',
      isPrimary: false,
    },
    paymentMethod: undefined,
    accountWasCreated: false,
    cartId: undefined,
    cartItems: [],
    paymentIntent: undefined,
    rememberPaymentMethod: false,
    reuseShippingForBilling: true,
    subscriptionId: undefined,
  })

  //@ts-ignore
  return <SignupContext.Provider value={[state, setState]}>
    {props.children}
  </SignupContext.Provider>
}

export function useSignupStore(): ISignupStore {
  //@ts-ignore
  const [state, setState] = useContext<[ISignupState, any]>(SignupContext)

  function setFirstName(firstName: string) {
    setState((s: ISignupState) => { return { ...s, firstName } })
  }

  function setLastName(lastName: string) {
    setState((s: ISignupState) => { return { ...s, lastName } })
  }

  function setEmail(email: string | undefined) {
    setState((s: ISignupState) => { return { ...s, email } })
  }

  function setPassword(password: string) {
    setState((s: ISignupState) => { return { ...s, password } })
  }

  function setShippingAddress(address: OrderAddress) {
    setState((s: ISignupState) => { return { ...s, shippingAddress: address } })
  }

  function setBillingAddress(address?: OrderAddress) {
    setState((s: ISignupState) => { return { ...s, billingAddress: address } })
  }

  function setPaymentMethod(paymentMethod?: PaymentMethod) {
    setState((s: ISignupState) => { return { ...s, paymentMethod: paymentMethod } })
  }

  function setAccountWasCreated(accountWasCreated: boolean) {
    setState((s: ISignupState) => { return { ...s, accountWasCreated } })
  }

  function setRememberPaymentMethod(rememberPaymentMethod: boolean) {
    setState((s: ISignupState) => { return { ...s, rememberPaymentMethod } })
  }

  function setReuseShippingForBilling(reuseShippingForBilling: boolean) {
    setState((s: ISignupState) => { return { ...s, reuseShippingForBilling } })
  }

  async function addToCart(item: CheckoutItem): Promise<void> {
    let cartId = state.cartId
    let cartItems = [...state.cartItems]
    let productItem: ProductPurchaseItem | undefined = undefined
    if (!cartId) {
      // try to get from storage
      cartId = getLocalStoreItem('cartId')
      if (cartId) {
        // cart saved in storage, get it by ID
        try {
          const cart = await ShopService.instance().getCartById(cartId)
          cartItems = cart.items
        } catch (e) {
          // create another cart
          cartId = await ShopService.instance().createCart()
          window.localStorage.setItem('cartId', cartId)
          productItem = await ShopService.instance().addProductToCart(cartId, item)
        }
      } else {
        // create cart
        cartId = await ShopService.instance().createCart()
        window.localStorage.setItem('cartId', cartId)
        productItem = await ShopService.instance().addProductToCart(cartId, item)
      }
      const storedUser = UserService.instance().getCurrentUser()
      const pi = await ShopService.instance().createIntent(cartId, storedUser?.id)
      setState((s: ISignupState) => { return { ...s, cartId, paymentIntent: pi } })
    } else {
      productItem = await ShopService.instance().addProductToCart(cartId, item)
    }

    let items = [...cartItems]
    const itemToRemove = items.find((p: ProductPurchaseItem) => p?.productTypeId === productItem?.productTypeId)
    if (itemToRemove) {
      const index = items.indexOf(itemToRemove)
      items.splice(index, 1)
    }
    if (productItem) {
      items.push(productItem)
    }
    setState((s: ISignupState) => { return { ...s, cartItems: items } })
  }

  function setSubscriptionId(subscriptionId: string) {
    setState((s: ISignupState) => { return { ...s, subscriptionId } })
  }

  return {
    firstName: state.firstName,
    lastName: state.lastName,
    email: state.email,
    password: state.password,
    shippingAddress: state.shippingAddress,
    billingAddress: state.billingAddress,
    paymentMethod: state.paymentMethod,
    accountWasCreated: state.accountWasCreated,
    cartId: state.cartId,
    cartItems: state.cartItems,
    paymentIntent: state.paymentIntent,
    rememberPaymentMethod: state.rememberPaymentMethod,
    reuseShippingForBilling: state.reuseShippingForBilling,
    subscriptionId: state.subscriptionId,
    setFirstName,
    setLastName,
    setEmail,
    setPassword,
    setShippingAddress,
    setBillingAddress,
    setPaymentMethod,
    setAccountWasCreated,
    addToCart,
    setRememberPaymentMethod,
    setReuseShippingForBilling,
    setSubscriptionId,
  }
}
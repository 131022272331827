import React from 'react'
import qvin_logo from '../../../images/qvin_logo.png'

export function Navbar() {
  return (
    <nav
      className='w-full max-h-14 py-4 fixed z-50 bg-white'
    >
      <div className="container mx-auto px-8 h-full">
        <a href="https://qvin.com">
          <img src={qvin_logo} alt="Qvin logo" className="md:h-10 h-8" />
        </a>
      </div>
    </nav>
  )
}
import React, { useEffect } from 'react'
import Button from '../components/common/button'
import { Navbar } from '../components/common/navbar/navbar'
import { usePixel } from '../utils/hooks'
import styles from './purchaseSuccess.module.css'
import icPurchased from '../images/purchased_successfully.svg'

export function PurchaseSuccessPage() {
  const track = usePixel()

  useEffect(() => {
    track('Purchase')
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <section className={styles.container}>
      <Navbar />
      <section className={styles.body}>
        <img src={icPurchased} alt="purchase success" />
        <h1 className="my-8">Purchased successfully!</h1>
        <a href="https://qvin.com/en/beta"><Button>Got it</Button></a>
      </section>
    </section>
  )
}
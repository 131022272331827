import { HumanIdentifiable, Identifiable } from "./common";

export interface OAuth2AccessToken {
  access_token: string
  token_type: string
  refresh_token: string
  expires_in: number
  scope: string
  roles: string
  userId: string
  jti: string
}

export interface LegalEntity extends Identifiable, HumanIdentifiable {
  createTime: Date
  //USER, ORGANIZATION
  type: string
  emailAddresses: Array<EmailAddress>
  addresses: Array<Address>
}

export interface User extends LegalEntity {
  firstName: string
  lastName: string
}

export interface EmailAddress extends Identifiable {
  emailAddress: string
  contactStatus: string
  primaryAddress: boolean
  legalEntityId: string
}

export interface Address extends Identifiable {
  addressLine: string
  secondaryAddressLine?: string
  city: string
  zip: string
  country?: string
  state?: string
  //enum typeEnum {  BILLING,  SHIPPING,  };
  type: 'SHIPPING' | 'BILLING' | undefined
  legalEntityId?: string
  validationMessage?: string
  externalId: string
  skipExternalValidation?: boolean
  isPrimary: boolean
}

export enum VerifyChannel {
  SMS = 'SMS',
  EMAIL = 'EMAIL',
}

export interface AddressValidationResult {
  status: AddressValidationStatus
  address: Address
  providerMessage: string
}

export enum AddressValidationStatus {
  VALID = 'VALID',
  UNRECOGNIZED = 'UNRECOGNIZED',
  WARNING = 'WARNING',
}

export interface ConsentForm extends Identifiable {
  name: string
  version: ConsentFormVersion
}

export interface ConsentFormVersion extends Identifiable {
  version: string
  content: string
}

export interface ConsentData {
  customerId: string
  consentStates: Array<ConsentState>
}

export interface ConsentState {
  consentFormId: string
  consentFormVersionId: string
  status: ConsentStatusType
}

export enum ConsentStatusType {
  NO_FORMS = 'NO_FORMS',
  DONT_WANT = 'DONT_WANT',
  CONFIRMED = 'CONFIRMED',
  COLLECTED = 'COLLECTED',
}
import React, { useState } from 'react'
import '../../../utils/utils.css'
import Button from '../../common/button'
import { useSignupStore } from '../../../store/SignupStore'
import OrderAddressInput from '../../common/orderAddressInput'
import { OrderAddress } from '../../../types/shop'
import { UserService } from '../../../service/UserService'
import { Address, AddressValidationStatus } from '../../../types/user'
import { getMessageForError } from '../../../utils/strings'
import { useNavigate, useLocation } from 'react-router-dom'
import { AddressWarningModal } from '../addressWarningModal'
import { ShopService } from '../../../service/ShopService'

function ShippingForm() {
  const {
    cartId,
    subscriptionId,
    shippingAddress,
    billingAddress,
    reuseShippingForBilling,
    setShippingAddress,
    setBillingAddress,
    setSubscriptionId,
  } = useSignupStore()

  const productId = new URLSearchParams(window.location.search).get('productId')
  const priceId = new URLSearchParams(window.location.search).get('priceId')
  const navigate = useNavigate()
  const location = useLocation()
  const [error, setError] = useState<string | undefined>(undefined)
  const [loading, setLoading] = useState<boolean>(false)
  const [addressWarningMessage, setAddressWarningMessage] = useState<string | null>(null)

  const continueDisabled = !shippingAddress
    || !shippingAddress.firstName || !shippingAddress.lastName
    || !shippingAddress.addressLine || !shippingAddress.city
    || !shippingAddress.zip || !shippingAddress.country
    || (shippingAddress.country === "US" && !shippingAddress.state)
    || shippingAddress.state === "NY"

  function onChangeAddress(shippingAddress?: OrderAddress) {
    if (!shippingAddress) {
      return
    }

    setShippingAddress({ ...shippingAddress, type: "SHIPPING", id: shippingAddress?.id })

    if (reuseShippingForBilling) {
      setBillingAddress({ ...shippingAddress, type: "BILLING", id: billingAddress?.id })
    }
  }

  async function goToNextStep() {
    if (continueDisabled) {
      return
    }

    setLoading(true)
    try {
      const validationResult = await UserService.instance().validateAddress(shippingAddress as Address)
      setLoading(false)
      if (validationResult.status === AddressValidationStatus.UNRECOGNIZED) {
        setError('Address is invalid. Please fix it and try again')
      } else {
        let adr = { ...shippingAddress, ...validationResult.address, id: shippingAddress?.id }
        let addressId: string | undefined = undefined

        if (cartId && !priceId) {
          addressId = await ShopService.instance().addShipmentInfoToCart(cartId, adr)
        } else if (priceId) {
          const sub = await ShopService.instance().saveSubscription(priceId, {
            id: subscriptionId,
            productTypeId: productId!!,
            shippingAddress: adr,
          })
          adr = { ...adr, id: sub.shippingAddress.id }
          addressId = sub.shippingAddress.id
          setSubscriptionId(sub.id!!)
        }

        setShippingAddress({ ...adr, id: addressId })
        if (reuseShippingForBilling) {
          setBillingAddress({ ...shippingAddress, ...validationResult.address, id: billingAddress?.id, type: 'BILLING' })
        }

        if (validationResult.status === AddressValidationStatus.WARNING) {
          setAddressWarningMessage(validationResult.providerMessage)
        } else {
          navigate(`../payment${location.search}`)
        }
      }
    } catch (e: any) {
      setLoading(false)
      setError(getMessageForError(e.code))
    }
  }

  return (
    <div className="flex-col text-left" tabIndex={0} style={{ outline: "none" }} onKeyUp={(e) => {
      if (e.key === "Enter") {
        goToNextStep()
      }
    }}>
      <p className="text-3xl m-0">Checkout</p>
      <p className="mt-8 mb-2">Shipping Address</p>
      <OrderAddressInput onChange={onChangeAddress} initValue={shippingAddress} />
      <p className="errorMsg text-sm mt-4">{error}</p>
      <Button
        id="button_ShippingAddressContinue"
        className="mt-4"
        isLoading={loading}
        disabled={continueDisabled}
        onClick={goToNextStep}>Continue</Button>
      <AddressWarningModal
        isVisible={addressWarningMessage !== null}
        message={addressWarningMessage}
        onPress={(confirm: boolean) => {
          setAddressWarningMessage(null)
          if (confirm) {
            navigate(`./payment${location.search}`)
          }
        }} />
    </div>
  )
}

export default ShippingForm
import React, { useEffect, useState } from 'react'
import ReactMarkdown from 'react-markdown'
import { Navbar } from '../components/common/navbar/navbar'
import { ConsentService } from '../service/ConsentService'
import { ConsentForm } from '../types/user'
import styles from './terms.module.css'

export function Terms() {
  const [tab, setTab] = useState<'TC' | 'Privacy' | 'Biobank'>('TC')

  const [consents, setConsents] = useState<Array<ConsentForm> | undefined>(undefined)
  const [fetchFailed, setFetchFailed] = useState<boolean>(false)

  useEffect(() => {
    async function loadConsents() {
      try {
        const terms = await ConsentService.instance().getConsentByNameAndVersion("Terms of use", null)
        const privacy = await ConsentService.instance().getConsentByNameAndVersion("Privacy policy", null)
        const biobanking = await ConsentService.instance().getConsentByNameAndVersion("Biobank and Research", null)
        setConsents([terms, privacy, biobanking])
      } catch (e) {
        setFetchFailed(true)
      }
    }
    loadConsents()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  if (fetchFailed) {
    return (
      <div className="w-full h-full font-walsheim">
        <Navbar />
        <div className="flex flex-col">
          <p className={`${styles.bodyCenter} ${styles.error}`}>Error loading consents. Please try again</p>
        </div>
      </div>
    )
  }

  if (!consents || consents.length !== 3) {
    return (
      <div className="w-full h-full font-walsheim">
        <Navbar />
        <div className="flex flex-col">
          <p className={styles.bodyCenter}>Loading</p>
        </div>
      </div>
    )
  }

  const termsConsent = consents.find(c => c.name.toLowerCase() === "terms of use")!.version.content
  const privacyConsent = consents.find(c => c.name.toLowerCase() === "privacy policy")!.version.content
  const biobankConsent = consents.find(c => c.name.toLowerCase() === "biobank and research")!.version.content

  return (
    <div className="w-full h-full font-walsheim">
      <Navbar />
      <nav
        className='w-full max-h-14 my-4 fixed z-50 bg-white mt-14'
      >
        <div className="mx-auto h-full">
          <div className="flex flex-row bg-white">
            <p
              className={`flex-1 text-center cursor-pointer ${tab === 'TC' ? styles.activeBorder : styles.border}`}
              onClick={() => setTab('TC')}
            >
              Terms of Use
          </p>
            <p
              className={`flex-1 text-center cursor-pointer ${tab === 'Privacy' ? styles.activeBorder : styles.border}`}
              onClick={() => setTab('Privacy')}
            >
              Privacy Policy
          </p>
            <p
              className={`flex-1 text-center cursor-pointer ${tab === 'Biobank' ? styles.activeBorder : styles.border}`}
              onClick={() => setTab('Biobank')}
            >
              Biobank and Research
          </p>
          </div>
        </div>
      </nav>
      <div className="flex flex-col">
        <div style={{ marginTop: "7rem" }}></div>
        <div className="mx-8 sm:mx-16 text-left flex flex-col items-center">
          {
            tab === 'TC'
            &&
            <ReactMarkdown className="prose py-8">{termsConsent}</ReactMarkdown>
          }
          {
            tab === 'Privacy'
            &&
            <ReactMarkdown className="prose py-8">{privacyConsent}</ReactMarkdown>
          }
          {
            tab === 'Biobank'
            &&
            <ReactMarkdown className="prose py-8">{biobankConsent}</ReactMarkdown>
          }
        </div>
      </div>
    </div>
  )
}

import React, { useEffect, useState } from 'react'
import Button from '../components/common/button'
import Input from '../components/common/input'
import { EMAIL_REGEX } from '../utils/constants'
import styles from './signin.module.css'
import { Link, useNavigate } from 'react-router-dom'
import { UserService } from '../service/UserService'
import { getMessageForError } from '../utils/strings'
import { VerifyChannel } from '../types/user'
import { ErrorCode, RequestError } from '../error/RequestError'
import PinField from 'react-pin-field'
import '../utils/utils.css'
import { SigninStore, useSigninStore } from '../store/SigninStore'
import Modal from '../components/common/modal'
import { isMobile } from "react-device-detect";
import PhoneInput from '../components/common/phoneInput'
import appStoreBadge from '../images/app_store_badge.svg'
import playStoreBadge from '../images/play_store_badge.png'

function Signin() {
  return (
    <SigninStore>
      <SigninContent />
    </SigninStore>
  )
}

function SigninContent() {
  const { target, isPhoneMissing } = useSigninStore()

  return (
    <div className="w-full h-full font-walsheim">
      {/* <EmptyNavBar windowHeight={windowHeight} /> */}
      <div className="h-screen flex flex-col items-center align-middle relative justify-center mx-4">
        {!isPhoneMissing && !target && <RequestCode />}
        {target && <InputCode />}
        {isPhoneMissing && !target && <AddNewPhone />}
      </div>
    </div>
  )
}

function RequestCode() {
  const { email, password, channel, setEmail, setPassword, setTarget, setIsPhoneMissing } = useSigninStore()
  const priceId = new URLSearchParams(window.location.search).get('priceId')
  const productId = new URLSearchParams(window.location.search).get('productId')
  const priceIdParam = priceId ? `&priceId=${priceId}` : ''

  const [emailError, setEmailError] = useState<string | undefined>(undefined)
  const [passwordError, setPasswordError] = useState<string | undefined>(undefined)
  const [error, setError] = useState<string | undefined>(undefined)
  const [showResetPasswordModal, setShowResetPasswordModal] = useState<boolean>(false)

  return (
    <>
      <p className="text-5xl m-0 text-center">Welcome back!</p>
      <p className="text-xl text-center my-4">Please log in to your account</p>
      <Input
        className="mt-4 w-4/5 md:w-2/4 lg:w-1/4"
        type="text"
        name="Email address"
        placeholder="Email address"
        required
        value={email || ''}
        error={emailError}
        onChange={value => {
          setEmail(value)

          const isEmailValid = value && EMAIL_REGEX.test(value)
          if (!isEmailValid) {
            setEmailError('Email has invalid format')
          } else {
            setEmailError(undefined)
          }
        }}
      />
      <Input
        className="mt-4 w-4/5 md:w-2/4 lg:w-1/4"
        type="password"
        name="Password"
        placeholder="Password"
        required
        value={password || ''}
        error={passwordError}
        onChange={value => {
          setPassword(value)

          if (!value) {
            setPasswordError("Field can't be empty")
          } else {
            setPasswordError(undefined)
          }
        }}
      />
      {
        isMobile
        &&
        <Link
          className="w-4/5 md:w-2/4 lg:w-1/4 mb-8 text-sm text-left cursor-pointer no-underline mt-2"
          style={{
            color: "#2b2b2e",
          }}
          target="_blank"
          to="https://app.qvin.com/forgotPassword">
          <p onClick={() => {
            setShowResetPasswordModal(true)
          }}>Forgot password?</p>
        </Link>
      }
      {
        !isMobile
        &&
        <p className="w-4/5 md:w-2/4 lg:w-1/4 mb-8 text-sm text-left cursor-pointer mt-2" onClick={() => {
          setShowResetPasswordModal(true)
        }}>Forgot password?</p>
      }
      <p className="errorMsg text-sm">{error}</p>
      <Button
        className="mt-8 w-4/5 md:w-2/4 lg:w-1/4"
        disabled={(!email || !password || emailError || passwordError) !== undefined}
        onClick={async () => {
          try {
            const target = await UserService.instance().requestVerificationCode(email!, password!, channel)
            setTarget(target)
          } catch (e: any) {
            if (e instanceof RequestError && e.code === ErrorCode.missingPhoneNumberForLogin) {
              setIsPhoneMissing(true)
              return
            }
            setError(getMessageForError(e.code))
          }
        }}
      >
        Sign In
      </Button>
      <p className="w-4/5 md:w-2/4 lg:w-1/4 mb-8 text-sm text-left my-4">
        Don't have account?{' '}
        <Link to={`/signup/account?productId=${productId}${priceIdParam}`} className={styles.signUp}>
          Sign up
        </Link>
      </p>
      <Modal isOpen={showResetPasswordModal}>
        <div className="flex flex-col items-center">
          <p className="cursor-pointer m-0 self-end" onClick={() => { setShowResetPasswordModal(false) }}>╳</p>
          <p className="text-center text-xl sm:text-3xl font-medium">You can reset your password from Qvin app.</p>
          <p className="text-center text-sm sm:text-base mt-4">In order to reset you password you must instal the Qvin app. But also, to use your Q-Pad, you will need to log in to the Qvin App.</p>
          <p className="text-center text-sm sm:text-base mb-4">A free personalized period tracker is waiting for you, and you should link your Q-Pad to your account once you receive them.</p>
          <div className="flex flex-col sm:flex-row items-center">
            <a className="w-2/3" href="https://apps.apple.com/us/app/qvin/id1493910790">
              <img className="w-full" alt="app_store_badge" src={appStoreBadge} />
            </a>
            <div style={{ margin: "1vh 3vw 1vh 3vw" }}></div>
            <a className="w-2/3" href="https://play.google.com/store/apps/details?id=com.qvin.customer">
              <img className="w-full " alt="play_store_badge" src={playStoreBadge} />
            </a>
          </div>
        </div>
      </Modal>
    </>
  )
}

function InputCode() {
  const priceId = new URLSearchParams(window.location.search).get('priceId')
  const productId = new URLSearchParams(window.location.search).get('productId')
  const priceIdParam = priceId ? `&priceId=${priceId}` : ''

  const {
    email,
    password,
    channel,
    code,
    target,
    isPhoneMissing,
    setCode,
    setTarget,
    setChannel,
    setIsPhoneMissing,
  } = useSigninStore()

  const navigate = useNavigate()
  const [resendTimeout, setResendTimeout] = useState<NodeJS.Timeout | undefined>(undefined)
  const [resendEnabled, setResendEnabled] = useState<boolean>(true)
  const [error, setError] = useState<string | undefined>(undefined)

  useEffect(() => {
    return () => {
      if (resendTimeout) {
        clearTimeout(resendTimeout)
      }
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  async function verifyByEmail() {
    try {
      const target = await UserService.instance().requestVerificationCode(email!, password!, VerifyChannel.EMAIL)
      setChannel(VerifyChannel.EMAIL)
      setTarget(target)
    } catch (e: any) {
      setError(getMessageForError(e.code))
    }
  }

  return (
    <>
      <p className="text-5xl m-0 text-center">Enter your verification code </p>
      <p className="text-xl text-center my-4">
        We sent {channel === VerifyChannel.SMS ? 'a SMS' : 'an email'} with a code to {target}. Please input the code
        within 10 minutes, or request a new code.
      </p>
      <div className={`mt-4 w-4/5 md:w-2/4 lg:w-1/4 ${styles.pinFieldContainer}`}>
        <PinField
          className={`${styles.pinField} input`}
          type="text"
          length={7}
          name="Verification code"
          required
          onChange={(value: string) => {
            setCode(value)
            setError(undefined)
          }}
        />
      </div>
      <p className="errorMsg text-sm text-left w-4/5 md:w-2/4 lg:w-1/4 mt-2">{error}</p>
      {channel === VerifyChannel.SMS && !isPhoneMissing && (
        <p className="w-4/5 md:w-2/4 lg:w-1/4 mb-8 text-sm text-left">
          Don’t have your phone available? Verfiy by email{' '}
          <span className={`${styles.signUp} cursor-pointer`} onClick={verifyByEmail}>
            here
          </span>
        </p>
      )}
      <Button
        className="mt-8 w-4/5 md:w-2/4 lg:w-1/4"
        disabled={(!code || error) !== undefined || code?.length !== 7}
        onClick={async () => {
          try {
            if (isPhoneMissing) {
              // save phone and login
              await UserService.instance().loginAndAddPhoneNumber(email!, password!, target!, code!)
            } else {
              // there already is phone number, just login
              await UserService.instance().login(email!, password!, code!, channel!)
            }
            // new user logged in, remove any existing cart
            window.localStorage.removeItem('cartId')
            navigate(`/buy/shipping?productId=${productId}${priceIdParam}`)
          } catch (e: any) {
            setError(getMessageForError(e.code))
          }
        }}
      >
        Next
      </Button>
      <Button
        className="mt-8 w-4/5 md:w-2/4 lg:w-1/4"
        disabled={!resendEnabled}
        secondary={true}
        onClick={async () => {
          try {
            await UserService.instance().requestVerificationCode(email!, password!, channel)
            setResendEnabled(false)
            setResendTimeout(
              setTimeout(() => {
                setResendEnabled(true)
              }, 10000)
            )
          } catch (e: any) {
            if (e instanceof RequestError && e.code === ErrorCode.missingPhoneNumberForLogin) {
              setIsPhoneMissing(true)
              return
            }
            setError(getMessageForError(e.code))
          }
        }}
      >
        Resend Code
      </Button>
    </>
  )
}

function AddNewPhone() {
  const { setTarget } = useSigninStore()
  const [phone, setPhone] = useState<string | undefined>(undefined)
  const [phoneError, setPhoneError] = useState<string | undefined>(undefined)

  return (
    <>
      <p className="text-5xl m-0 text-center">Protecting your information</p>
      <p className="text-xl text-center my-8">
        Welcome back! At Qvin we do everything we can to safeguard your information. Please input your mobile phone
        number to enable two factor-authentication.
      </p>
      <PhoneInput
        className="mt-4 w-4/5 md:w-2/4 lg:w-1/4 font-walsheim"
        name="Phone number"
        placeholder="Phone number"
        value={phone || ''}
        error={phoneError}
        onChange={value => {
          setPhone(value)
          setPhoneError(undefined)
        }}
      />
      <Button
        className="mt-8 w-4/5 md:w-2/4 lg:w-1/4"
        disabled={!phone}
        onClick={async () => {
          if (!phone) {
            setPhoneError('Field is mandatory')
          }

          try {
            const isUsed = await UserService.instance().isPhoneUsed(phone!)
            if (isUsed) {
              setPhoneError(getMessageForError(ErrorCode.phoneNumberAlreadyUsed))
              return
            }
            await UserService.instance().requestVerificationCodeTo(phone!, VerifyChannel.SMS)
            setTarget(phone)
          } catch (e: any) {
            setPhoneError(getMessageForError(e.code))
          }
        }}
      >
        Next
      </Button>
    </>
  )
}

export default Signin

import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Button from '../components/common/button'
import styles from './createAccount.module.css'
import kitAndApp from '../images/kit_and_app.png'
import { Navbar } from '../components/common/navbar/navbar'
import { UserService } from '../service/UserService'

function CreateAccount() {
  const navigate = useNavigate()
  const priceId = new URLSearchParams(window.location.search).get('priceId')
  const productId = new URLSearchParams(window.location.search).get('productId')
  const priceIdParam = priceId ? `&priceId=${priceId}` : ''

  return (
    <div className="w-full h-full font-walsheim">
      <Navbar />
      <div className={`h-screen flex flex-col items-center align-middle relative justify-center ${styles.content}`}>
        <img style={{
          objectFit: "scale-down"
        }} alt="kit and mobile app" className="w-2/4" src={kitAndApp} />
        <p className={`${styles.title} my-4`}>Is this your first time using a Q-Pad?</p>
        <div className={styles.buttons}>
          <Button id="buttonYes" onClick={() => {
            UserService.instance().logout()
            navigate(`/signup/account?productId=${productId}${priceIdParam}`)
          }}>
            Yes
          </Button>
          <Link style={{ textDecoration: "none", marginLeft: "8px" }} to={`/signin/shipping?productId=${productId}${priceIdParam}`}>
            <Button id="buttonNo">
              No
            </Button>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default CreateAccount
import React from 'react'
import { default as ReactPhoneInput } from 'react-phone-input-2'
import 'react-phone-input-2/lib/plain.css'
import styles from './PhoneInput.module.css'

interface IProps {
  id?: string
  label?: string
  error?: string
  value?: string
  onChange: (youngValue: string) => void
  name?: string
  className?: string
  placeholder?: string
}

const PhoneInput = ({ id, label, error, value, onChange, name, className, placeholder }: IProps) => {
  const shouldRenderWithoutError = () => {
    return error == null || error.trim().length === 0
  }
  return (
    <div id={id} className={`${className} ${styles.field}`}>
      <ReactPhoneInput
        country={'us'}
        value={value}
        countryCodeEditable={false}
        onChange={(value) => { onChange(`+${value}`) }}
        containerClass={styles.container}
        inputClass={styles.input}
        buttonClass={styles.dropdownButton}
        placeholder={placeholder}
      />
      <div className={shouldRenderWithoutError() ? styles.baseLine : styles.baseLineWithError} />
      <div className={`${styles.inputFooter} text-left`}>
        {shouldRenderWithoutError() ? (
          <span className={styles.inputLabel}>{label}</span>
        ) : (
          <span className={`${styles.inputLabelWithError} font-walsheim`}>{error}</span>
        )}
      </div>
    </div>
  )
}

export default PhoneInput

import React, { useState } from 'react'
import { useIsMounted } from '../../../utils/hooks'
import styles from './Button.module.css'

interface IProps {
  id?: string
  className?: string
  onClick?: () => (void | Promise<void>)
  disabled?: boolean
  isLoading?: boolean
  secondary?: boolean
}

function Button({ id, className, onClick, disabled, children, secondary, isLoading }: IProps & { children: any }) {
  const isMounted = useIsMounted()
  const [loading, setLoading] = useState(isLoading ?? false)

  async function onClickHandler() {
    if (onClick && typeof onClick === "function") {
      setLoading(true)
      try {
        await onClick()
      } finally {
        if (isMounted()) {
          setLoading(false)
        }
      }

    }
  }

  const renderReady = () => (
    <button id={id} className={`${className} ${styles.button} ${secondary ? styles.secondary : ''}`} onClick={onClickHandler} disabled={disabled ? disabled : false}>
      {children}
    </button>
  )
  const renderProgress = () => (
    <button className={`${className} ${styles.button} ${styles.loading} ${secondary ? styles.secondary : ''}`}>
      <span className="invisible">{children}</span>
      <span className={styles.loader} />
    </button>
  )
  return loading || isLoading ? renderProgress() : renderReady()
}

export default Button

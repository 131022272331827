import axios from 'axios'
import { NetworkError } from '../error/NetworkError';
import { ErrorCode, RequestError } from '../error/RequestError';
import { ConsentForm } from '../types/user';
import { buildHeaders } from '../utils/api';
import { SECURE_API_ENDPOINT } from '../utils/constants';

export class ConsentService {
  public static instance(): ConsentService {
    if (ConsentService._instance == null) {
      ConsentService._instance = new ConsentService();
    }
    return ConsentService._instance;
  }

  private static _instance: ConsentService;

  private constructor() { }

  public async getConsentByNameAndVersion(name: string, version: string | null): Promise<ConsentForm> {
    try {
      const response = await axios.get<ConsentForm>(`${SECURE_API_ENDPOINT}/account_api/consentForm`, {
        headers: buildHeaders(),
        params: {
          consentFormName: name,
          version: version,
        }
      })
      return response.data
    } catch (e) {
      if (e instanceof NetworkError) {
        throw new RequestError(ErrorCode.unknown);
      }

      throw e
    }
  }
}
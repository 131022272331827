import React from 'react'
import styles from './Input.module.css'
import '../../../utils/utils.css'

export interface IInputProps {
  id?: string;
  value?: string;
  error?: string;
  type?: string;
  name?: string;
  placeholder?: string;
  className?: string;
  required?: boolean;
  onChange: (value: string) => void
}

function Input(props: IInputProps) {
  return (
    <div className={`${props.error ? 'invalid' : ''} ${props.className} text-left`}>
      <input
        id={props.id}
        className={`input ${props.error ? styles.redUnderline : ''}`}
        type={props.type}
        name={props.name}
        placeholder={props.placeholder}
        required={props.required}
        value={props.value || ''}
        onChange={e => props.onChange(e.target.value)} />
      {
        props.error
        &&
        <span className={`text-xs ${styles.invalid}`}>{props.error}</span>
      }
    </div>
  )
}

export default Input
import React from 'react'
import styles from './servicesDown.module.css'
import backendDown from '../images/backend_down.svg'
import { Navbar } from '../components/common/navbar/navbar'

function ServicesDown() {
  return (
    <div className="w-full h-full font-walsheim">
      <Navbar />
      <div className={`flex flex-col items-center align-middle relative justify-center ${styles.content}`}>
        <p className={`${styles.title} mt-14`}>Sorry...</p>
        <img style={{
          objectFit: "scale-down"
        }}
          alt="services down"
          className="w-2/4 md:w-1/4 my-4"
          src={backendDown}
        />
        <p className={`${styles.title} my-4 mx-16`}>We're building products on the bleeding edge of technology, right now squashing bugs and fixing issues. Please check back soon, or contact us at <a href="mailto:admin@qvin.com">admin@qvin.com</a></p>
      </div>
    </div>
  )
}

export default ServicesDown
import React, { useEffect, useState } from 'react'
import styles from './signup.module.css'
import SignupProgress from '../components/shop/signupProgress'
import CartSummary from '../components/shop/cartSummary'
import { SingupStore, useSignupStore } from '../store/SignupStore'
import ShippingForm from '../components/shop/shippingForm'
import PaymentForm from '../components/shop/paymentForm'
import ReviewOrder from '../components/shop/reviewOrder'
import { UserService } from '../service/UserService'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { useNavigate } from 'react-router'
import { Navbar } from '../components/common/navbar/navbar'
import { Route, Routes, useLocation } from 'react-router-dom'
import { usePixel } from '../utils/hooks'

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY!);

function Buy() {
  return (
    <Elements
      stripe={stripePromise}
      options={{
        fonts: [{
          family: 'GT Walsheim',
          src: 'url(https://qvin.com/static/GT-Walsheim-Pro-Regular-526a7baeaf4ec1259031548024c4dbb7.otf)',
          weight: '500',
        },]
      }}
    >
      <SingupStore>
        <div className="w-full h-full font-walsheim">
          <Navbar />
          <div className={`flex flex-col relative justify-center ${styles.content}`}>
            <BuyContent />
          </div>
        </div>
      </SingupStore>
    </Elements>
  )
}

function BuyContent() {
  const priceId = new URLSearchParams(window.location.search).get('priceId')
  const productId = new URLSearchParams(window.location.search).get('productId')

  const location = useLocation()
  const navigate = useNavigate()

  const {
    shippingAddress,
    billingAddress,
    setFirstName,
    setLastName,
    setEmail,
    setShippingAddress,
    setBillingAddress,
  } = useSignupStore()
  const [loadingUser, setLoadingUser] = useState(true)

  const track = usePixel()
  const step = location.pathname.replaceAll("/buy/", "")

  useEffect(() => {
    if (step === "shipping") {
      track('ShippingInfo')
    } else if (step === "payment") {
      track('InitiateCheckout')
    } else if (step === "review") {
      track('ConfirmCheckout')
    }
  }, [step]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    async function initState() {
      try {
        const user = UserService.instance().getCurrentUser()
        if (user) {
          setFirstName(user.firstName)
          setLastName(user.lastName)
          let emailAdr = user.emailAddresses.find((a) => a.primaryAddress === true)
          if (!emailAdr && user.emailAddresses && user.emailAddresses.length > 0) {
            emailAdr = user.emailAddresses[0]
          }
          setEmail(emailAdr?.emailAddress)

          let adr = user.addresses?.find((a) => a.isPrimary === true)
          if (!adr && user.addresses && user.addresses.length > 0) {
            adr = user.addresses[0]
          }

          setShippingAddress({
            ...shippingAddress,
            ...adr,
            firstName: user.firstName,
            lastName: user.lastName,
            phone: undefined,
            email: emailAdr?.emailAddress,
            id: shippingAddress?.id,
          })
          setBillingAddress({
            ...shippingAddress,
            ...adr,
            firstName: user.firstName,
            lastName: user.lastName,
            phone: undefined,
            email: emailAdr?.emailAddress,
            id: billingAddress?.id,
          })
          navigate(`./shipping${location.search}`)
          setLoadingUser(false)
        } else {
          navigate(`/createAccount?productId=${productId}&priceId=${priceId}`)
        }
      } catch (e) {
        navigate(`/createAccount?productId=${productId}&priceId=${priceId}`)
      }
    }
    initState()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  if (loadingUser) {
    return <div style={{
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    }}>
      Loading...
    </div>;
  }

  return (
    <div>
      <div style={{ marginTop: "5rem" }}></div>
      <SignupProgress className="mx-0 sm:mx-32 lg:mx-64" hideAccountForm={true} />
      <div className="my-8 flex flex-col-reverse sm:flex-row mx-0 sm:mx-16 lg:mx-32">
        <div className="flex-1 sm:mr-4">
          <div className="flex-1">
            <Routes>
              <Route path="/shipping" element={<ShippingForm />} />
              <Route path="/review" element={<ReviewOrder />} />
            </Routes>
          </div>
          {
            <div style={{
              // card data is saved in Stripe elements
              // we can't restore state because we don't have data
              // so always render this view to mantain state
              // just swtich display attribute for visibility
              display: step === 'payment' ? 'inline' : 'none'
            }}>
              <PaymentForm />
            </div>
          }
        </div>
        <div className={`flex-1 ${step === 'shipping' || step === 'review' ? '' : 'hidden'} sm:block sm:ml-4`}>
          <CartSummary className="my-4" step={step} />
        </div>
      </div>
    </div>
  )
}

export default Buy
import React, { useEffect, useState } from 'react'
import { ProductPurchaseItem, Bundle } from '../../../types/shop'
import styles from './CartSummary.module.css'
import { ShopService } from '../../../service/ShopService'
import { Money, Price } from '../../../types/shop'
import { formatInterval, formatPrice } from '../../../utils/shop'
import { useSignupStore } from '../../../store/SignupStore'
import { ProductService } from '../../../service/ProductService'
import { usePixel } from '../../../utils/hooks'

interface ISubscriptionSummaryProps {
  className?: string
  hideTotal?: boolean
  step: string
}

function CartSummary(props: ISubscriptionSummaryProps) {
  const track = usePixel()
  const { cartId, cartItems, addToCart } = useSignupStore()

  const priceId = new URLSearchParams(window.location.search).get('priceId')
  const productId = new URLSearchParams(window.location.search).get('productId')

  const [bundle, setBundle] = useState<Bundle | undefined>(undefined)
  const [price, setPrice] = useState<Price | undefined>(undefined)
  const totalPrice: Money = price?.price ?? {
    amount: 0,
    currency: bundle?.price?.price?.currency ?? 'USD',
  }

  if (cartItems && !price) {
    for (let item of cartItems) {
      if (item?.price) {
        totalPrice.amount += item.price.amount
      }
    }
  }

  const cartItem = cartItems?.find(p => p?.productTypeId === bundle?.id)

  useEffect(() => {
    async function loadProducts() {
      const bundles = await ProductService.instance().getBundles()
      const usedBundle = bundles.find(p => p.id === productId)
      setBundle(usedBundle)
    }

    loadProducts()
  }, [productId])

  useEffect(() => {
    if (!bundle?.id) {
      return
    }

    if (priceId) {
      // subscription
      ShopService.instance()
        .getPricesForProducts([bundle?.id])
        .then(plans => {
          const planById = plans.find(p => p.externalId === priceId)
          setPrice(planById)

          track('AddToCart', {
            value: planById?.price?.amount,
            currency: planById?.price?.currency,
          })
        })
    } else {
      // one time purchase
      if (!cartId && bundle) {
        addToCart({
          productId: bundle.id!,
          amount: 1,
        })

        track('AddToCart', {
          value: bundle?.price?.price?.amount,
          currency: bundle?.price?.price?.currency,
        })
      }
    }
  }, [bundle?.id]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={`${props.className} text-left`}>
      <p className="m-0">Included in your order</p>
      <div
        style={{
          marginTop: '1rem',
          marginBottom: '1rem',
          height: '1px',
          width: '100%',
          backgroundColor: '#ECEBE9',
        }}
      ></div>
      {bundle && (
        <Product bundle={bundle} plan={price} cartItem={cartItem} />
      )}
      <div
        style={{
          marginTop: '1rem',
          marginBottom: '1rem',
          height: '1px',
          width: '100%',
          backgroundColor: '#ECEBE9',
        }}
      ></div>
      <div className={`${props.step === 'review' ? '' : 'hidden'} sm:block`}>
        <div className="flex flex-row">
          <p className="m-0 flex-1">Subtotal</p>
          <p id="textSubTotalPrice" className="m-0">{formatPrice(totalPrice)}</p>
        </div>
        <div className="flex flex-row mt-4">
          <p className="m-0 flex-1">Delivery</p>
          <p className="m-0">Free</p>
        </div>
        <div
          style={{
            marginTop: '1rem',
            marginBottom: '1rem',
            height: '1px',
            width: '100%',
            backgroundColor: '#ECEBE9',
          }}
        ></div>
        <div className="flex flex-row">
          <p className="m-0 flex-1 font-bold">Total</p>
          {!price && <p id="textTotalPrice" className="m-0 font-bold">{formatPrice(totalPrice)}</p>}
          {price && <p id="textPlanPrice" className="m-0 font-bold">{formatPrice(price.price)}</p>}
        </div>
      </div>
    </div>
  )
}

export default CartSummary

interface IProductProps {
  bundle: Bundle
  cartItem?: ProductPurchaseItem
  plan?: Price
}

function Product(props: IProductProps) {
  const { addToCart } = useSignupStore()

  return (
    <div className="flex flex-row mr-2 text-left">
      {
        props.bundle.productData?.imageUrl
        &&
        <img className="h-8 w-8 mr-2" alt="product logo" src={props.bundle.productData.imageUrl} />
      }
      <div className="flex flex-col mr-2 flex-1">
        <p className="m-0">{props.bundle.productData.name}</p>
        {props.plan && (
          <p className={`m-0 text-xs ${styles.greyedOut}`}>
            {formatInterval(props.plan.interval, props.plan.intervalCount)}
          </p>
        )}
        {!props.plan && (
          <div className="flex flex-row">
            <span
              className="cursor-pointer"
              id="idDecrementCount"
              onClick={() => {
                if (props.cartItem?.quantity && props.cartItem?.quantity - 1 < 1) {
                  return
                }

                addToCart({
                  productId: props.bundle.id!,
                  amount: -1,
                })
              }}
            >
              -
            </span>
            <span id="textCartItemQuantity" className="mx-4">{props.cartItem?.quantity}</span>
            <span
              className="cursor-pointer"
              id="idIncrementCount"
              onClick={() => {
                addToCart({
                  productId: props.bundle.id!,
                  amount: 1,
                })
              }}
            >
              +
            </span>
          </div>
        )}
      </div>
      <div className="flex flex-col items-end">
        {
          props.plan
          &&
          <p className="m-0">{formatPrice(props.plan.price)}</p>
        }
        {
          !props.plan
          &&
          <p className="m-0">{formatPrice(props.bundle.price.price)}</p>
        }
      </div>
    </div>
  )
}

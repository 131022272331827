import React, { useState } from 'react'
import { Transition } from '@headlessui/react'

type Props = {
  id?: string
  /** True if the modal is open */
  isOpen: boolean
  /** Contents of the modal */
  children: React.ReactNode
}

const Modal: React.FC<Props> = ({ id, isOpen, children }) => {
  const [isZIndexPositive, setIsZIndexPositive] = useState(isOpen)

  return (
    <Transition
      id={id}
      show={isOpen}
      afterLeave={() => setIsZIndexPositive(false)}
      beforeEnter={() => setIsZIndexPositive(true)}
    >
      <div className={`fixed inset-0 overflow-y-auto ${isZIndexPositive ? 'z-10' : '-z-1'}`}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 transition-opacity" aria-hidden="true">
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
          </Transition.Child>

          <Transition.Child
            enter="ease-out duration-300"
            enterFrom="opacity-0 sm:scale-95"
            enterTo="opacity-100 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 sm:scale-100"
            leaveTo="opacity-0 sm:scale-95"
          >
            <div
              className="bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform -translate-x-1/2 -translate-y-1/2 transition-all sm:my-8 sm:align-middle w-10/12 sm:max-w-lg sm:w-full sm:p-6 fixed top-1/2 left-1/2"
              role="dialog"
              aria-modal="true"
              aria-labelledby="modal-headline"
            >
              {children}
            </div>
          </Transition.Child>
        </div>
      </div>
    </Transition>
  )
}

export default Modal

import React from 'react'
import Button from '../common/button'
import Modal from '../common/modal'

interface IAddressWarningModalProps {
  isVisible: boolean;
  message: string | null;
  onPress: (confirm: boolean) => void;
}

export function AddressWarningModal(props: IAddressWarningModalProps) {
  return <Modal isOpen={props.isVisible}>
    <div className="flex flex-col items-center">
      <p className="cursor-pointer m-0 self-end" onClick={() => { props.onPress(false) }}>╳</p>
      <p className="text-center text-xl sm:text-3xl font-medium">Are you sure you want to use this address?</p>
      <p className="text-center text-sm sm:text-base mt-4">{props.message}</p>
      <div className="flex flex-col sm:flex-row items-center">
        <Button
          id="buttonYes"
          className="mt-4"
          onClick={() => props.onPress(true)}>
          Yes
        </Button>
        <div style={{ margin: "1vh 3vw 1vh 3vw" }}></div>
        <Button
          id="buttonNo"
          className="mt-4"
          secondary={true}
          onClick={() => props.onPress(false)}>
          No
        </Button>
      </div>
    </div>
  </Modal>
}
import axios from 'axios'
import { NetworkError } from '../error/NetworkError';
import { ErrorCode, RequestError } from '../error/RequestError';
import { BillingInterval, CheckoutItem, Money, OrderAddress, PaymentIntent, PaymentMethod, Subscription, Price } from '../types/shop';
import { ProductPurchaseItem, ProductPurchaseOrder } from '../types/shop';
import { buildAuthHeaders } from '../utils/api';
import { SECURE_API_ENDPOINT } from '../utils/constants';

export class ShopService {
  public static instance(): ShopService {
    if (ShopService._instance == null) {
      ShopService._instance = new ShopService();
    }
    return ShopService._instance;
  }

  private static _instance: ShopService;

  private constructor() { }

  public async createCart(): Promise<string> {
    try {
      const response = await axios.post(`${SECURE_API_ENDPOINT}/account_api/cart/create`, null, {
        headers: buildAuthHeaders(),
      })
      if (response.status !== 200) {
        throw new RequestError(ErrorCode.failedCreateCart);
      }
      return response.data
    } catch (e) {
      if (e instanceof NetworkError) {
        throw new RequestError(ErrorCode.failedCreateCart);
      }

      throw e
    }
  }

  public async getCartById(cartId: string): Promise<ProductPurchaseOrder> {
    try {
      const response = await axios.get<ProductPurchaseOrder>(`${SECURE_API_ENDPOINT}/account_api/cart/${cartId}`)
      return response.data;
    } catch (e) {
      if (e instanceof NetworkError) {
        throw new RequestError(ErrorCode.failedFetchCart);
      }

      throw e
    }
  }

  public async getPricesForProducts(productIds: Array<string>): Promise<Array<Price>> {
    try {
      const response = await axios.get<Array<Price>>(`${SECURE_API_ENDPOINT}/account_api/prices/product/${productIds.join(',')}`, {
        transformResponse: (resp) => {
          let datas: Array<any> = JSON.parse(resp)
          return datas.map((data) => {
            return {
              externalId: data.externalId as string,
              productId: data.productId as string,
              externalProductId: data.externalProductId as string,
              price: {
                amount: data.price.amount as number,
                currency: data.price.currency
              } as Money,
              interval: (BillingInterval as any)[data.interval],
              intervalCount: data.intervalCount as number,
            } as Price
          })
        }
      }
      );


      return response.data;
    } catch (e) {
      if (e instanceof NetworkError) {
        throw new RequestError(ErrorCode.failedLoadingProductPlans);
      }

      throw e
    }
  }

  public async attachPaymentMethodToCustomer(userId: string, paymentMethodId: string | undefined | null): Promise<void> {
    try {
      await axios.post(`${SECURE_API_ENDPOINT}/account_api/shop/user/${userId}/attachPaymentMethod`, null, {
        headers: buildAuthHeaders(),
        params: {
          paymentMethodId,
        }
      })
    } catch (e: any) {
      if (e instanceof NetworkError) {
        if (e?.body?.message === "card_declined") {
          throw new RequestError(ErrorCode.cardDeclined);
        }
        throw new RequestError(ErrorCode.failedSavingPaymentMethod)
      }

      throw e
    }
  }

  public async addProductToCart(cartId: string, item: CheckoutItem): Promise<ProductPurchaseItem> {
    try {
      const response = await axios.post<ProductPurchaseItem>(`${SECURE_API_ENDPOINT}/account_api/cart/${cartId}/item`, item, {
        headers: buildAuthHeaders(),
      })

      return response.data
    } catch (e) {
      if (e instanceof NetworkError) {
        throw new RequestError(ErrorCode.failedAddingProduct)
      }

      throw e
    }
  }

  public async updatePaymentStatus(productPurchaseOrderId: string | undefined): Promise<void> {
    try {
      const response = await axios.post(`${SECURE_API_ENDPOINT}/account_api/cart/${productPurchaseOrderId}/updatePaymentStatus`, null, {
        headers: buildAuthHeaders(),
      })

      return response.data
    } catch (e) {
      if (e instanceof NetworkError) {
        throw new RequestError(ErrorCode.failedPurchase)
      }

      throw e
    }
  }

  public async createIntent(productPurchaseOrderId: string, userId?: string): Promise<PaymentIntent> {
    try {
      const response = await axios.post<PaymentIntent>(`${SECURE_API_ENDPOINT}/account_api/cart/${productPurchaseOrderId}/intent`, null, {
        headers: buildAuthHeaders(),
        params: userId ? {
          userId,
        } : undefined,
      })

      return response.data
    } catch (e) {
      if (e instanceof NetworkError) {
        throw new RequestError(ErrorCode.failedPurchase)
      }

      throw e
    }
  }

  public async getPaymentMethods(userId?: string): Promise<Array<PaymentMethod> | undefined> {
    if (!userId) {
      return undefined
    }

    try {
      const response = await axios.get<Array<PaymentMethod>>(`${SECURE_API_ENDPOINT}/account_api/shop/user/${userId}/paymentMethods`, {
        headers: buildAuthHeaders()
      })
      return response.data;
    } catch (e) {
      if (e instanceof NetworkError) {
        throw new RequestError(ErrorCode.failedFetchPaymentMethods);
      }

      throw e
    }
  }

  public async addShipmentInfoToCart(cartId: string, address: OrderAddress): Promise<string> {
    try {
      const response = await axios.post(`${SECURE_API_ENDPOINT}/account_api/cart/${cartId}/addShipmentInfo`, address, {
        headers: buildAuthHeaders(),
      })
      if (response.status !== 200) {
        throw new RequestError(ErrorCode.failedAddCartData);
      }
      return response.data
    } catch (e) {
      if (e instanceof NetworkError) {
        throw new RequestError(ErrorCode.failedAddCartData);
      }

      throw e
    }
  }

  public async saveSubscription(priceId: string, subscription: Subscription): Promise<Subscription> {
    try {
      const response = await axios.post<Subscription>(`${SECURE_API_ENDPOINT}/account_api/shop/subscription/save`, subscription, {
        params: {
          priceId,
        }
      })
      if (response.status !== 200) {
        throw new RequestError(ErrorCode.failedCreateSubscription);
      }
      return response.data
    } catch (e) {
      if (e instanceof NetworkError) {
        throw new RequestError(ErrorCode.failedCreateSubscription);
      }

      throw e
    }
  }

  public async activateSubscription(userId: string, subscriptionId: string, priceId: string, paymentMethodId: string): Promise<string> {
    try {
      const response = await axios.post(`${SECURE_API_ENDPOINT}/account_api/shop/user/${userId}/subscription/${subscriptionId}/activate`, null, {
        headers: buildAuthHeaders(),
        params: {
          priceId,
          paymentMethodId,
        }
      })
      if (response.status !== 200) {
        throw new RequestError(ErrorCode.failedCreateSubscription);
      }
      return response.data
    } catch (e) {
      if (e instanceof NetworkError) {
        throw new RequestError(ErrorCode.failedCreateSubscription);
      }

      throw e
    }
  }
}
import React, { useEffect, useState } from "react"
import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from "@stripe/react-stripe-js"
import { useSignupStore } from "../../../store/SignupStore"
import { OrderAddress, PaymentMethod } from "../../../types/shop"
import Button from "../../common/button"
import Input from "../../common/input"
import OrderAddressInput from "../../common/orderAddressInput"
import styles from "./PaymentForm.module.css"
import { useCurrentUser } from "../../../utils/hooks"
import { ShopService } from "../../../service/ShopService"
import { UserService } from "../../../service/UserService"
import { Address, AddressValidationStatus } from "../../../types/user"
import { getMessageForError } from "../../../utils/strings"
import { StripeCardNumberElement } from "@stripe/stripe-js"
import { useNavigate, useLocation } from "react-router"
import { AddressWarningModal } from "../addressWarningModal"

const inputStyle = {
  style: {
    base: {
      color: '#32325D',
      fontWeight: 400,
      fontFamily: 'GT Walsheim',
      fontSize: '18px',
      '::placeholder': {
        color: '#CFD7DF',
      },
      ':-webkit-autofill': {
        color: '#e39f48',
      },
    },
    invalid: {
      color: '#E25950',
      '::placeholder': {
        color: '#FFCCA5',
      },
    },
  },
}

function PaymentForm() {
  const user = useCurrentUser()
  const stripe = useStripe()
  const elements = useElements()
  const productId = new URLSearchParams(window.location.search).get('productId')
  const priceId = new URLSearchParams(window.location.search).get('priceId')

  const {
    cartId,
    subscriptionId,
    firstName,
    lastName,
    email,
    shippingAddress,
    billingAddress,
    paymentMethod,
    rememberPaymentMethod,
    reuseShippingForBilling,
    setBillingAddress,
    setPaymentMethod,
    setRememberPaymentMethod,
    setReuseShippingForBilling,
    setSubscriptionId,
  } = useSignupStore()

  const location = useLocation()
  const navigate = useNavigate()
  const [nameOwner, setNameOwner] = useState<string>('')
  const [nameOwnerError, setNameOwnerError] = useState<string | undefined>(undefined)
  const [wasCardChanged, setWasCardChanged] = useState<boolean>(false)
  const [cardNumberComplete, setCardNumberComplete] = useState<boolean>(false)
  const [expiryComplete, setExpiryComplete] = useState<boolean>(false)
  const [cvcComplete, setCvcComplete] = useState<boolean>(false)
  const [paymentMethods, setPaymentMethods] = useState<Array<PaymentMethod> | undefined>(undefined)
  const [addNewPaymentMethod, setAddNewPaymentMethod] = useState<boolean>(false)
  const [error, setError] = useState<string | undefined>(undefined)
  const [loading, setLoading] = useState<boolean>(false)
  const [addressWarningMessage, setAddressWarningMessage] = useState<string | null>(null)

  const continueDisabled = (!paymentMethod
    &&
    (!cardNumberComplete
      || !expiryComplete
      || !cvcComplete))
    ||
    (!billingAddress
      || !billingAddress.firstName || !billingAddress.lastName
      || !billingAddress.addressLine || !billingAddress.city
      || !billingAddress.zip || !billingAddress.country
      || (billingAddress.country === "US" && !billingAddress.state))

  useEffect(() => {
    if (firstName && lastName) {
      setNameOwner(`${firstName} ${lastName}`)
    }
  }, [firstName, lastName])

  useEffect(() => {
    if (reuseShippingForBilling) {
      setBillingAddress({ ...shippingAddress, type: "BILLING", id: billingAddress?.id })
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (user) {
      ShopService.instance().getPaymentMethods(user?.id).then((pms) => {
        if (pms && pms.length > 0) {
          setPaymentMethods(pms)
          setPaymentMethod(addNewPaymentMethod ? undefined : pms[0])
        } else {
          setAddNewPaymentMethod(true)
        }
      }).catch(e => {
        setAddNewPaymentMethod(true)
        // just let the user add new payment method
      })
    }
  }, [user?.id]) // eslint-disable-line react-hooks/exhaustive-deps

  function onChangeAddress(adr?: OrderAddress) {
    if (adr) {
      setBillingAddress({ ...adr, type: "BILLING", email, id: billingAddress?.id })
    }
  }

  function onChangeCardDetails() {
    setWasCardChanged(true)
  }

  async function createPaymentMethod() {
    if (!paymentMethod || wasCardChanged) {
      const cardElement = elements?.getElement(CardNumberElement) as StripeCardNumberElement
      const pm = await stripe?.createPaymentMethod({
        type: 'card',
        card: cardElement,
      })
      if (pm?.paymentMethod) {
        setPaymentMethod({
          id: pm.paymentMethod.id,
          last4: pm.paymentMethod.card!.last4
        })
      }
      setWasCardChanged(false)
    }

    setLoading(false)
    navigate(`./review${location.search}`)
  }

  async function goToNextStep() {
    if (continueDisabled) {
      return
    }

    setLoading(true)

    if (!reuseShippingForBilling) {
      try {
        const validationResult = await UserService.instance().validateAddress(billingAddress as Address)
        if (validationResult.status === AddressValidationStatus.UNRECOGNIZED) {
          setLoading(false)
          setError('Address is invalid. Please fix it and try again')
        } else {
          let addressId: string | undefined = undefined
          let billingAdr = { ...billingAddress, ...validationResult.address }

          if (cartId && !priceId) {
            addressId = await ShopService.instance().addShipmentInfoToCart(cartId, billingAdr)
          } else if (priceId) {
            const sub = await ShopService.instance().saveSubscription(priceId, {
              id: subscriptionId,
              productTypeId: productId!!,
              shippingAddress: shippingAddress,
              billingAddress: billingAdr,
            })
            addressId = sub.billingAddress?.id
            setSubscriptionId(sub.id!!)
          }

          setBillingAddress({ ...billingAdr, id: addressId })

          if (validationResult.status === AddressValidationStatus.WARNING) {
            setLoading(false)
            setAddressWarningMessage(validationResult.providerMessage)
          } else {
            await createPaymentMethod()
          }
        }
      } catch (e: any) {
        setLoading(false)
        setError(getMessageForError(e.code))
      }
    } else {
      let addressId: string | undefined = undefined
      let billingAdr: OrderAddress = { ...shippingAddress, type: "BILLING", id: billingAddress?.id }

      if (cartId && !priceId) {
        addressId = await ShopService.instance().addShipmentInfoToCart(cartId, billingAdr)
      } else if (priceId) {
        const sub = await ShopService.instance().saveSubscription(priceId, {
          id: subscriptionId,
          productTypeId: productId!!,
          shippingAddress: shippingAddress,
          billingAddress: billingAdr,
        })
        addressId = sub.billingAddress?.id
        setSubscriptionId(sub.id!!)
      }

      setBillingAddress({ ...billingAdr, id: addressId })
      await createPaymentMethod()
    }
  }

  return (
    <div tabIndex={0} style={{ outline: "none" }} className="text-left" onKeyUp={(e) => {
      if (e.key === "Enter") {
        goToNextStep()
      }
    }}>
      <p className="text-3xl m-0">Checkout</p>
      <p className="mt-8 mb-2 text-xl">Credit Card Details</p>
      {
        paymentMethods
        &&
        <select
          className="my-4"
          name="Payment methods"
          value={addNewPaymentMethod || !paymentMethod ? "new_pm" : (paymentMethod?.id ?? "new_pm")}
          onChange={(e) => {
            if (e.target.value === "new_pm") {
              setPaymentMethod(undefined)
              setAddNewPaymentMethod(true)
              return
            }

            let pm = paymentMethods.find(p => p?.id === e.target.value)
            setPaymentMethod(pm)
            setAddNewPaymentMethod(false)
          }}>
          {
            paymentMethods?.map((pm) => {
              return <option key={pm?.id} value={pm?.id}>Ending with **** {pm.last4}</option>
            })
          }
          <option key="new_pm" value="new_pm">Add New Credit Card</option>
        </select>
      }
      {
        (addNewPaymentMethod || !paymentMethods)
        &&
        <>
          <Input
            id="input_text_NameOwner"
            className="sm:mr-4 flex-1"
            type="text"
            name="Name Owner"
            placeholder="Name Owner"
            required
            value={nameOwner || ''}
            error={nameOwnerError}
            onChange={value => {
              setNameOwner(value)

              if (!value || value.length < 2) {
                setNameOwnerError('Field must be at least 2 letters')
              } else {
                setNameOwnerError(undefined)
              }
            }} />
          <CardNumberElement
            id="input_text_CardNumber"
            options={inputStyle}
            className={`${styles.input} mt-4`}
            onChange={(e) => {
              onChangeCardDetails()
              setCardNumberComplete(e.complete)
            }} />
          <div className="flex flex-row">
            <CardExpiryElement
              id="inputCardExpiry"
              options={inputStyle}
              className={`${styles.input} mt-4 mr-4`}
              onChange={(e) => {
                onChangeCardDetails()
                setExpiryComplete(e.complete)
              }} />
            <CardCvcElement
              id="input_text_CardCVC"
              options={inputStyle}
              className={`${styles.input} mt-4`}
              onChange={(e) => {
                onChangeCardDetails()
                setCvcComplete(e.complete)
              }} />
          </div>
          {
            !priceId
            &&
            <div className="flex flex-row items-center mt-4">
              <input
                id="checkbox_RememberPaymentInfo"
                className="mr-2"
                type="checkbox"
                checked={rememberPaymentMethod}
                onChange={(e) => {
                  setRememberPaymentMethod(e.target.checked)
                }} />
              <label>Save credit card for future purchases</label>
            </div>
          }
        </>
      }
      <p className="mt-8 mb-2 text-xl">Billing Address</p>
      <div className="flex flex-row items-center">
        <input
          id="checkbox_UseShippingForBilling"
          className="mr-2"
          type="checkbox"
          checked={reuseShippingForBilling}
          onChange={(e) => {
            setReuseShippingForBilling(e.target.checked)
          }} />
        <label>Use same address as shipping</label>
      </div>
      <OrderAddressInput className={`mt-4 ${reuseShippingForBilling ? 'hidden' : ''}`} onChange={onChangeAddress} />
      <p className="errorMsg text-sm">{error}</p>
      <Button
        id="button_Continue"
        className="mt-4"
        isLoading={loading}
        disabled={continueDisabled}
        onClick={goToNextStep}>Continue</Button>
      <AddressWarningModal
        isVisible={addressWarningMessage !== null}
        message={addressWarningMessage}
        onPress={(confirm: boolean) => {
          setAddressWarningMessage(null)
          if (confirm) {
            setLoading(true)
            createPaymentMethod()
          }
        }} />
    </div>
  )
}

export default PaymentForm
import React, { useState } from "react"
import { OrderAddress } from "../../../types/shop"
import { statesOptions } from "../../../utils/countries"
import Input from "../input"

interface IOrderAddressInputProps {
  className?: string
  initValue?: OrderAddress
  onChange: (address?: OrderAddress) => void
}

interface OrderAddressErrors {
  firstName?: string
  lastName?: string
  addressLine?: string
  city?: string
  zip?: string
  state?: string
}

function OrderAddressInput(props: IOrderAddressInputProps) {
  const [address, setAddress] = useState<OrderAddress>(props.initValue ?? {
    id: undefined,
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    addressLine: '',
    secondaryAddressLine: undefined,
    city: '',
    zip: '',
    country: 'US',
    state: 'AK',
    type: undefined,
    externalId: '',
    isPrimary: false,
    legalEntityId: undefined,
    validationMessage: undefined,
    skipExternalValidation: undefined,
  })

  const [errors, setErrors] = useState<OrderAddressErrors>({
    firstName: undefined,
    lastName: undefined,
    addressLine: undefined,
    city: undefined,
    zip: undefined,
    state: undefined,
  })

  function updateAddress(address: OrderAddress, errors: OrderAddressErrors) {
    setAddress(address)
    setErrors(errors)
    if (errors.firstName || errors.lastName || errors.addressLine || errors.city || errors.zip || errors.state) {
      props.onChange(undefined)
    } else {
      props.onChange(address)
    }
  }

  return (
    <div className={props.className}>
      <div className="flex flex-col sm:flex-row">
        <Input
          id="input_orderAddressFirstName"
          className="sm:mr-4 flex-1"
          type="text"
          name="First name"
          placeholder="First name"
          required
          value={address?.firstName || ''}
          error={errors.firstName}
          onChange={value => {
            let newErrors = errors
            if (!value || value.length < 2) {
              newErrors = { ...newErrors, firstName: 'Field must be at least 2 letters' }
            } else {
              newErrors = { ...newErrors, firstName: undefined }
            }

            updateAddress({ ...address, firstName: value }, newErrors)
          }} />
        <Input
          id="input_orderAddressLastName"
          className="mt-4 sm:mt-0 flex-1"
          type="text"
          name="Last name"
          placeholder="Last name"
          required
          value={address?.lastName || ''}
          error={errors.lastName}
          onChange={value => {
            let newErrors = errors
            if (!value || value.length < 2) {
              newErrors = { ...newErrors, lastName: 'Field must be at least 2 letters' }
            } else {
              newErrors = { ...newErrors, lastName: undefined }
            }

            updateAddress({ ...address, lastName: value }, newErrors)
          }} />
      </div>
      <Input
        id="input_orderAddressLine1"
        className="mt-4"
        type="text"
        name="Address Line"
        placeholder="Address Line"
        required
        value={address?.addressLine}
        error={errors.addressLine}
        onChange={value => {
          let newErrors = errors
          if (!value || value.length < 2) {
            newErrors = { ...newErrors, addressLine: 'Field must be at least 2 letters' }
          } else {
            newErrors = { ...newErrors, addressLine: undefined }
          }

          updateAddress({ ...address, addressLine: value }, newErrors)
        }} />
      <Input
        id="input_orderAddressLine2"
        className="mt-4"
        type="text"
        name="Address Line 2"
        placeholder="Address Line 2"
        required
        value={address?.secondaryAddressLine}
        onChange={value => {
          updateAddress({ ...address, secondaryAddressLine: value }, errors)
        }} />
      <select
        id="select_orderAddressCountry"
        className="mt-4"
        name="countries"
        value={address?.country}
        onChange={(e) => {
          let state: string | undefined = address?.state
          if (address?.country !== 'US' && e.target.value === 'US') {
            // set default state when choosing US
            state = 'AK'
          }
          if (e.target.value !== 'US') {
            // clear state
            state = undefined
          }

          updateAddress({ ...address, country: e.target.value, state }, errors)
        }}>
        <option key="US" value="US">United States</option>
      </select>
      <Input
        id="input_orderAddressCity"
        className="mt-4"
        type="text"
        name="City"
        placeholder="City"
        required
        value={address?.city}
        error={errors.city}
        onChange={value => {
          let newErrors = errors
          if (!value || value.length < 2) {
            newErrors = { ...newErrors, city: 'Field must be at least 2 letters' }
          } else {
            newErrors = { ...newErrors, city: undefined }
          }

          updateAddress({ ...address, city: value }, newErrors)
        }} />
      {
        address?.country === "US"
        &&
        <select
          id="input_orderAddressState"
          className="mt-4"
          name="states"
          value={address?.state}
          onChange={(e) => {
            let newErrors = errors
            if (e.target.value === "NY") {
              newErrors = { ...newErrors, state: 'Unfortunately, we can\'t sell products in the state of New York.' }
            } else {
              newErrors = { ...newErrors, state: undefined }
            }

            updateAddress({ ...address, state: e.target.value }, newErrors)

          }}>
          {
            statesOptions.map((so) => {
              return <option key={so.value} value={so.value}>{so.label}</option>
            })
          }
        </select>
      }
      {
        errors.state
        &&
        <p className="errorMsg text-sm">{errors.state}</p>
      }
      <Input
        id="input_orderAddressPostcode"
        className="mt-4"
        type="text"
        name="Postcode"
        placeholder="Postcode"
        required
        value={address?.zip}
        error={errors.zip}
        onChange={value => {
          let newErrors = errors
          if (!value || value.length < 2) {
            newErrors = { ...newErrors, zip: 'Field must be at least 2 letters' }
          } else {
            newErrors = { ...newErrors, zip: undefined }
          }

          updateAddress({ ...address, zip: value }, newErrors)
        }} />
    </div>
  )
}

export default OrderAddressInput
import React, { useEffect, useState } from "react"
import { ShopService } from "../../../service/ShopService"
import { UserService } from "../../../service/UserService"
import { useSignupStore } from "../../../store/SignupStore"
import { findCountry, findState } from "../../../utils/countries"
import { Link, useNavigate, useLocation } from "react-router-dom"
import Button from "../../common/button"
import styles from "./ReviewOrder.module.css"
import { getMessageForError } from "../../../utils/strings"
import { ErrorCode, RequestError } from "../../../error/RequestError"
import { CardNumberElement, useElements, useStripe } from "@stripe/react-stripe-js"
import { StripeCardNumberElement } from "@stripe/stripe-js"
import icInfo from '../../../images/ic_info.svg'
import { useCurrentUser } from "../../../utils/hooks"
import { Address, ConsentForm, ConsentState, ConsentStatusType } from '../../../types/user';

function ReviewOrder() {
  const stripe = useStripe()
  const elements = useElements()

  const navigate = useNavigate()
  const location = useLocation()
  const user = useCurrentUser()
  const priceId = new URLSearchParams(window.location.search).get('priceId')

  const {
    firstName,
    lastName,
    email,
    password,
    shippingAddress,
    billingAddress,
    paymentMethod,
    accountWasCreated,
    cartId,
    paymentIntent,
    rememberPaymentMethod,
    reuseShippingForBilling,
    subscriptionId,
    setAccountWasCreated,
  } = useSignupStore()

  const [error, setError] = useState<string>('')
  const [showAgreeTerms, setShowAgreeTerms] = useState<boolean>(true)
  const [consentsToAdd, setConsentsToAdd] = useState<Array<ConsentForm> | undefined>(undefined)
  const [acceptedTerms, setAcceptedTerms] = useState<boolean>(false)

  const shippingCountry = findCountry(shippingAddress.country!!)
  const shippingState = findState(shippingAddress.state)
  const billingCountry = findCountry(billingAddress.country!!)
  const billingState = findState(billingAddress.state)
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    if (user) {
      UserService.instance().getConsentsNeedingUpdate(user.id!).then((consents) => {
        if (consents.length === 0) {
          setShowAgreeTerms(false)
        } else {
          setConsentsToAdd(consents)
        }
      }).catch((_) => {
        // if it fails then we will keep default behaviour of showing terms
      });
    }
  }, [user?.id]) // eslint-disable-line react-hooks/exhaustive-deps

  const isOnSignupPage = window.location.href.includes('signup')

  async function createSubscription(userId: string, paymentMethodId: string): Promise<void> {
    await ShopService.instance().attachPaymentMethodToCustomer(userId, paymentMethodId)
    await ShopService.instance().activateSubscription(userId, subscriptionId!, priceId!, paymentMethodId)
  }

  async function purchase(userId: string, clientSecret: string): Promise<void> {
    const paymentIntentResult = await stripe?.confirmCardPayment(clientSecret, {
      payment_method: paymentMethod?.id ?? {
        card: elements?.getElement(CardNumberElement) as StripeCardNumberElement
      },
    })

    if (paymentIntentResult?.error?.code === "card_declined") {
      throw new RequestError(ErrorCode.cardDeclined)
    } else if (paymentIntentResult?.error?.code === "incorrect_cvc") {
      throw new RequestError(ErrorCode.invalidCvc)
    } else if (paymentIntentResult?.error) {
      throw new RequestError(ErrorCode.failedPurchase)
    }

    await ShopService.instance().updatePaymentStatus(cartId)
    if (rememberPaymentMethod) {
      await ShopService.instance().attachPaymentMethodToCustomer(userId, paymentIntentResult?.paymentIntent?.payment_method)
    }
  }

  async function goToNextStep() {
    if (showAgreeTerms && !acceptedTerms) {
      return
    }

    setLoading(true)
    setError('')
    let userId: string | undefined = undefined
    if (isOnSignupPage) {
      if (accountWasCreated) {
        // account was already created
        userId = UserService.instance().getCurrentUser()?.id
      } else {
        try {
          const token = await UserService.instance().register({
            firstName: firstName!,
            lastName: lastName!,
            email: email!,
            password: password!,
            addressLine: shippingAddress.addressLine,
            city: shippingAddress.city,
            state: shippingAddress.state,
            zip: shippingAddress.zip,
            country: shippingAddress.country!!,
          })
          // remember we created account already in case
          // we get an error down below when creating the payment
          // so we don't try to recreate account again and get existing email error
          setAccountWasCreated(true)

          userId = token.userId
        } catch (e: any) {
          setLoading(false)
          setError(getMessageForError(e.code))
          return
        }
      }

      if (!reuseShippingForBilling) {
        try {
          await UserService.instance().saveAddress({ ...billingAddress, legalEntityId: userId, id: undefined })
        } catch (e: any) {
          setLoading(false)
          setError(getMessageForError(e.code))
          return
        }
      }
    } else {
      const user = UserService.instance().getCurrentUser()
      userId = user?.id

      let primaryAdr = user?.addresses?.find((a) => a.isPrimary === true)
      if (!primaryAdr && user?.addresses && user.addresses.length > 0) {
        primaryAdr = user.addresses[0]
      }

      try {
        if (primaryAdr && !isSameAddress(primaryAdr, shippingAddress)) {
          // this is new address so save it for use later
          await UserService.instance().saveAddress({ ...shippingAddress, legalEntityId: userId, id: undefined })
        }

        if (!reuseShippingForBilling && primaryAdr && !isSameAddress(primaryAdr, billingAddress)) {
          await UserService.instance().saveAddress({ ...billingAddress, legalEntityId: userId, id: undefined })
        }
      } catch (e: any) {
        setLoading(false)
        setError(getMessageForError(e.code))
        return
      }

      if (consentsToAdd && consentsToAdd.length > 0) {
        const consentStates = new Array<ConsentState>()

        for (const consent of consentsToAdd!) {
          consentStates.push({
            consentFormId: consent.id!,
            consentFormVersionId: consent.version.id!,
            status: ConsentStatusType.CONFIRMED,
          })
        }

        await UserService.instance().saveCustomerConsents({
          customerId: userId!,
          consentStates: consentStates,
        })
      }
    }

    try {
      if (priceId) {
        // handle subscription
        await createSubscription(userId!, paymentMethod!.id)
      } else {
        // handle purchase
        await purchase(userId!, paymentIntent!.clientSecret)
      }
      window.localStorage.removeItem('cartId')
      setLoading(false)
      navigate(isOnSignupPage ? '/accountCreated' : '/purchaseSuccess', { replace: true })
    } catch (e: any) {
      setLoading(false)
      setError(getMessageForError(e.code))
    }
  }

  return (
    <div tabIndex={0} style={{ outline: "none" }} className="text-left" onKeyUp={(e) => {
      if (e.key === "Enter") {
        goToNextStep()
      }
    }}>
      <p className="text-3xl m-0" id="textCheckout">Checkout</p>
      <p className="mt-8 mb-2 text-xl" id="textBasicInfo">Basic Informations</p>
      <div className="flex flex-row items-center">
        <div className="flex-1">
          <p className="m-0 text-sm" id="textFirstLastName">{firstName} {lastName}</p>
          <p className="m-0 text-sm" id="textEmail">{email}</p>
        </div>
        {
          isOnSignupPage
          &&
          <p id="linkChangeAccount" className="m-0 cursor-pointer text-sm" onClick={() => { navigate(`./account${location.search}`) }}>Change</p>
        }
      </div>
      <p className="mt-8 mb-2 text-xl" id="textShippingAddress">Shipping Address</p>
      <div className="flex flex-row items-center">
        <div className="flex-1">
          <p className="m-0 text-sm">{shippingAddress?.addressLine + (shippingAddress?.secondaryAddressLine ? `, ${shippingAddress?.secondaryAddressLine}` : '')}</p>
          <p className="m-0 text-sm">{shippingAddress?.city + (shippingState ? `, ${shippingState.value}` : '') + ' ' + shippingAddress?.zip}</p>
          <p className="m-0 text-sm">{shippingCountry!.label}</p>
        </div>
        <p id="linkChangeShippingAddress" className="m-0 cursor-pointer text-sm" onClick={() => { navigate(`./shipping${location.search}`) }}>Change</p>
      </div>
      <p className="mt-8 mb-2 text-xl">Credit Card</p>
      <div className="flex flex-row items-center">
        <div className="flex-1">
          <p className="m-0 text-sm">Credit card with ending **** {paymentMethod!.last4}</p>
        </div>
        <p id="linkChangePaymentInfo" className="m-0 cursor-pointer text-sm" onClick={() => { navigate(`./payment${location.search}`) }}>Change</p>
      </div>
      <p className="mt-8 mb-2 text-xl" id="textBillingAddress">Billing Address</p>
      <div className="flex flex-row items-center">
        <div className="flex-1">
          <p className="m-0 text-sm">{billingAddress?.addressLine + (billingAddress?.secondaryAddressLine ? `, ${billingAddress?.secondaryAddressLine}` : '')}</p>
          <p className="m-0 text-sm">{billingAddress?.city + (billingState ? `, ${billingState.value}` : '') + ' ' + billingAddress?.zip}</p>
          <p className="m-0 text-sm">{billingCountry!.label}</p>
        </div>
        <p id="linkChangeBillingAddress" className="m-0 cursor-pointer text-sm" onClick={() => { navigate(`./payment${location.search}`) }}>Change</p>
      </div>
      <div className="bg-qvin-red p-4 rounded-md my-4">
        <div className="flex flx-row mb-2">
          <p className="flex-1 text-white m-0">Our principles</p>
          <Link id="linkPrinciplesInfo" to="/terms" target="_blank" rel="noreferrer" className={styles.terms}><img src={icInfo} alt="info" /></Link>
        </div>
        <p className="text-xs text-white">Trusting your information with us is a responsibility we take extremely seriously. Please click here and learn more about our data principles, privacy policy, and general terms and conditions.</p>
      </div>
      {
        showAgreeTerms
        &&
        <div className="flex flex-row items-center">
          <input id="checkboxAgreeTerms" type="checkbox" checked={acceptedTerms} className="mr-2" onChange={() => setAcceptedTerms(!acceptedTerms)} />
          <label className="text-sm">I agree to the <Link to="/terms" target="_blank" rel="noreferrer" className={styles.terms}>Terms and Conditions</Link></label>
        </div>
      }
      <p className={`${styles.invalid} text-sm`}>{error}</p>
      <Button
        id="buttonPlaceOrder"
        className="mt-4"
        disabled={showAgreeTerms && !acceptedTerms}
        isLoading={loading}
        onClick={goToNextStep}>Place Order</Button>
    </div>
  )
}

function isSameAddress(lhs: Address, rhs: Address): boolean {
  return lhs.addressLine === rhs.addressLine &&
    lhs.secondaryAddressLine === rhs.secondaryAddressLine &&
    lhs.city === rhs.city &&
    lhs.zip === rhs.zip &&
    lhs.country === rhs.country &&
    lhs.state === rhs.state;
}

export default ReviewOrder

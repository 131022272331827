import axios, { AxiosResponse } from 'axios'
import { NetworkError } from '../error/NetworkError';
import { ErrorCode, RequestError } from '../error/RequestError';
import { ContentfulProductType, Bundle } from '../types/shop';
import { buildHeaders } from '../utils/api';
import { SECURE_API_ENDPOINT } from '../utils/constants';
import { ShopService } from './ShopService';

export class ProductService {
  public static instance(): ProductService {
    if (ProductService._instance == null) {
      ProductService._instance = new ProductService();
    }
    return ProductService._instance;
  }

  private static _instance: ProductService;

  private constructor() { }

  public async getBundles(): Promise<Array<Bundle>> {
    try {
      const response = await axios.get<Array<Bundle>>(`${SECURE_API_ENDPOINT}/product_api/bundles`, {
        headers: buildHeaders(),
      })
      const bundles = response.data

      const contentfulProducts = await this.getContentfulProductTypeBySecureNames(bundles.map(b => b.name))
      const prices = await ShopService.instance().getPricesForProducts(bundles.map(b => b.id!))

      for (const bundle of bundles) {
        if (contentfulProducts) {
          bundle.productData = contentfulProducts.find(cp => cp.secureName === bundle.name)!
        }
        bundle.subscriptionPrices = prices.filter(p => p.productId === bundle.id && p.interval)
        bundle.price = prices.find(p => p.productId === bundle.id && !p.interval)!
      }

      return bundles
    } catch (e) {
      if (e instanceof NetworkError) {
        throw new RequestError(ErrorCode.failedLoadingProduct);
      }

      throw e
    }
  }

  public async getContentfulProductTypeBySecureNames(secureNames: Array<string>): Promise<Array<ContentfulProductType> | undefined> {
    let response: AxiosResponse | undefined = undefined
    try {
      response = await axios.get<any>(`${SECURE_API_ENDPOINT}/contentful_delivery_api/entries`, {
        headers: {
          ...buildHeaders(),
        },
        params: {
          'content_type': 'productType',
          'fields.secureName[in]': secureNames.join(','),
        }
      })

      const items = response.data.items
      if (!items || items?.length === 0) {
        return undefined;
      }

      const assets: Array<any> = response.data.includes?.Asset

      const products: Array<ContentfulProductType> = []

      for (const item of items) {
        const imageId = item.fields.image.sys.id;
        const asset = assets.find(a => a.sys.id === imageId)
        const imageUrl = asset?.fields?.file?.url ? `http:${asset?.fields?.file?.url}` : undefined

        const product = {
          secureName: item.fields.secureName,
          name: item.fields.name,
          description: item.fields.description,
          imageUrl: imageUrl,
          displayShop: item.fields.displayShop,
        }
        products.push(product)
      }

      return products
    } catch (e) {
      if (e instanceof NetworkError) {
        throw new RequestError(ErrorCode.failedLoadingProduct);
      }

      throw e
    }
  }
}
import { CreateStamp } from "./common"
import { Address } from "./user"

export interface Price {
  externalId: string
  productId: string
  externalProductId: string
  price: Money
  interval: BillingInterval
  intervalCount: number
}

export interface Money {
  amount: number
  currency: Currency
}

type Currency = 'USD'

export enum BillingInterval {
  DAY,
  WEEK,
  MONTH,
  YEAR,
}

export interface OrderAddress extends Address {
  firstName?: string
  lastName?: string
  phone?: string
  email?: string
}

export interface Subscription extends CreateStamp {
  customerId?: String
  status?: SubscriptionStatus
  statusHistory?: Array<SubscriptionStatus>
  productTypeId: String
  quantity?: number
  externalId?: String
  billingInterval?: BillingInterval
  billingIntervalCount?: number
  shippingAddress: OrderAddress
  billingAddress?: OrderAddress
  invoices?: Array<Invoice>
  price?: Money
  currentPeriodEnd?: Date
  paymentMethodId?: String
}

export interface SubscriptionStatus extends CreateStamp {
  type: SubscriptionStatusType
}

export enum SubscriptionStatusType {
  INCOMPLETE,
  INCOMPLETE_EXPIRED,
  TRIALING,
  ACTIVE,
  PAST_DUE,
  CANCELED,
  UNPAID,
}

export interface Invoice extends CreateStamp {
  status: InvoiceStatus
  statusHistory: Array<InvoiceStatus>
}

export interface InvoiceStatus extends CreateStamp {
  type: InvoiceStatusType
}

export enum InvoiceStatusType {
  ACTIVE,
  PAST_DUE,
  UNPAID,
  CANCELED,
  INCOMPLETE,
  INCOMPLETE_EXPIRED,
  TRIALING,
  ALL,
  ENDED,
}

export interface CheckoutItem {
  productId: string
  amount: number
}

export interface PaymentIntent {
  id: string
  paymentMethodId: string
  clientSecret: string
  orderId: string
}

export interface PaymentMethod {
  id: string
  last4: string
}

export interface ProductPurchaseItem {
  id: string
  price: Money
  productTypeId: string
  quantity: number
}

export interface ProductPurchaseOrder {
  id: string
  items: ProductPurchaseItem[]
  price: Money
  clientSecret?: string
}

export interface Bundle extends CreateStamp {
  name: string
  productData: ContentfulProductType
  subscriptionPrices: Array<Price>
  price: Price
}


export interface ContentfulProductType {
  secureName: string
  name: string
  description: string
  imageUrl: string | undefined
  displayShop: string
}
import React, { useEffect } from 'react'
import { Navbar } from '../components/common/navbar/navbar'
import mobileFrame from '../images/mobileframe.svg'
import appStoreBadge from '../images/app_store_badge.svg'
import playStoreBadge from '../images/play_store_badge.png'
import styles from './accountCreated.module.css'
import { usePixel } from '../utils/hooks'

function AccountCreated() {
  const track = usePixel()

  useEffect(() => {
    track('Purchase')
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="w-full h-full font-walsheim">
      <Navbar />
      <div className="flex flex-col justify-center items-center mx-4 sm:mx-32">
        <div style={{ marginTop: "5rem" }}></div>
        <img style={{ height: "25vh" }} alt="mobile app" src={mobileFrame} />
        <div className="flex flex-col items-center">
          <p className="text-2xl font-medium text-center m-0 mt-8">Your Q-Pad will arrive soon!</p>
          <p className="text-2xl font-medium text-center m-0">Please install our app now. </p>
          <p className="text-center m-0 mt-8">To use your Q-Pad, you will need to log in to the Qvin App with the e-mail and password you have just created.</p>
          <p className="text-center m-0 my-8">A free personalized period tracker is waiting for you, and you should link your Q-Pad to your account once you receive them.</p>
          <div className="flex flex-row">
            <a href="https://apps.apple.com/us/app/qvin/id1493910790">
              <img className={styles.appStoreBadge} alt="app_store_badge" src={appStoreBadge} />
            </a>
            <div style={{ margin: "1vh 3vw 1vh 3vw" }}></div>
            <a href="https://play.google.com/store/apps/details?id=com.qvin.customer">
              <img className={styles.playStoreBadge} alt="play_store_badge" src={playStoreBadge} />
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AccountCreated
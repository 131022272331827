export class RequestError extends Error {
  public code: ErrorCode;

  public constructor(code: ErrorCode) {
    super();
    this.code = code;
  }
}

export enum ErrorCode {
  unknown,
  failLogin,
  failedCreateAccount,
  failedCreateCart,
  failedFetchCart,
  failedAddProduct,
  failedLoadingProduct,
  failedLoadingProductPlans,
  emailAlreadyExists,
  failedSavingPaymentMethod,
  failedCreateSubscription,
  shippingAddressMissing,
  billingAddressMissing,
  addressNotSupported,
  invalidEmailFormat,
  errorVerifying2faCode,
  error2faCodeNotValid,
  missingPhoneNumberForLogin,
  cardDeclined,
  invalidCvc,
  failedAddingProduct,
  failedPurchase,
  errorRequesting2faCode,
  wrongEmailOrPassword,
  tooManyLoginAttempts,
  verifyTwoFactorFailed,
  invalidTwoFactorCode,
  invalidPhoneNumber,
  phoneNumberAlreadyUsed,
  missingVerificationCode,
  failedFetchPaymentMethods,
  invalidAddress,
  failedAddCartData,
}
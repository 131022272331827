import { BillingInterval, Money, Bundle, Price } from "../types/shop"

export function formatInterval(interval: BillingInterval, count: number) {
  switch (interval) {
    case BillingInterval.DAY:
      {
        if (count === 1) {
          return "Daily"
        }

        return `Every ${count} days`
      }
    case BillingInterval.WEEK:
      {
        if (count === 1) {
          return "Weekly"
        }

        return `Every ${count} weeks`
      }
    case BillingInterval.MONTH:
      {
        if (count === 1) {
          return "Monthly"
        } else if (count === 3) {
          return "Quarterly"
        }

        return `Every ${count} months`
      }
    case BillingInterval.YEAR:
      {
        if (count === 1) {
          return "Yearly"
        }

        return `Every ${count} years`
      }
  }
}

export function getRecurringAmountInDays(plan: Price): number {
  switch (plan.interval) {
    case BillingInterval.DAY:
      return plan.intervalCount
    case BillingInterval.WEEK:
      return plan.intervalCount * 7
    case BillingInterval.MONTH:
      return plan.intervalCount * 31
    case BillingInterval.YEAR:
      return plan.intervalCount * 365
    default:
      return 0
  }
}

export function formatPrice(money: Money) {
  if (!money) {
    return ''
  }
  let symbol = money.currency.toString()
  if (symbol === "USD") {
    symbol = "$"
  }

  return `${symbol}${money.amount}`
}

export function getDescriptionOfPrice(bundle: Bundle, plan: Price): string | undefined {
  if (plan.price.amount > bundle.price.price.amount) {
    return undefined
  }

  const percentage = 100 -
    (plan.price.amount * 100 / bundle.price.price.amount)
  if (percentage < 1) {
    return ''
  }

  return `${Math.round(percentage)}% discount on original price (${formatPrice(bundle.price.price)})`
}
import axios from 'axios';
import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes, useNavigate } from 'react-router-dom';
import './App.css';
import { NetworkError } from './error/NetworkError';
import { ServerError } from './error/ServerError';
import AccountCreated from './screens/accountCreated';
import Buy from './screens/buy';
import { Consent } from './screens/consent';
import CreateAccount from './screens/createAccount';
import { PurchaseSuccessPage } from './screens/purchaseSuccess';
import ServicesDown from './screens/servicesDown';
import Signin from './screens/signin';
import Signup from './screens/signup';
import { Terms } from './screens/terms';

// setup global HTTP error interceptor
axios.interceptors.response.use((config) => config, (error) => {
  if (error?.response && error.response?.status) {
    if (error.response.status === 502) {
      return Promise.reject(new ServerError())
    } else {
      return Promise.reject(new NetworkError(error.response.status, error.response.data))
    }

  }
  return Promise.reject(error)
})

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <AppRoutes />
      </BrowserRouter>
    </div>
  );
}

function AppRoutes() {
  const navigate = useNavigate()

  useEffect(() => {
    window.addEventListener("error", (event) => {
      if (event.error instanceof ServerError) {
        navigate('/servicesDown')
      }
    });

    window.addEventListener("unhandledrejection", (event: PromiseRejectionEvent) => {
      if (event.reason instanceof ServerError) {
        navigate('/servicesDown')
      }
    });
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return <Routes>
    <Route path="/createAccount" element={<CreateAccount />} />
    <Route path="/signin/*" element={<Signin />} />
    <Route path="/signup/*" element={<Signup />} />
    <Route path="/accountCreated" element={<AccountCreated />} />
    <Route path="/buy/*" element={<Buy />} />
    <Route path="/purchaseSuccess" element={<PurchaseSuccessPage />} />
    <Route path="/consent/:name" element={<Consent />} />
    <Route path="/terms" element={<Terms />} />
    <Route path="/servicesDown" element={<ServicesDown />} />
  </Routes>
}

export default App;

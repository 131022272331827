import { ErrorCode } from "../error/RequestError";

export function getMessageForError(e: ErrorCode): string {
  switch (e) {
    case ErrorCode.failedCreateAccount:
      return 'Failed to create account. Please try again.'
    case ErrorCode.failedCreateCart:
      return 'Failed to create cart. Please try again.'
    case ErrorCode.failedFetchCart:
      return 'Failed to fetch cart data. Please try again.'
    case ErrorCode.failedAddProduct:
      return 'Failed to add product. Please try again.'
    case ErrorCode.failedLoadingProduct:
      return 'Failed to load product. Please try again.'
    case ErrorCode.failedLoadingProductPlans:
      return 'Failed to load product subscription plans. Please try again.'
    case ErrorCode.emailAlreadyExists:
      return 'Email is already in use.'
    case ErrorCode.failedSavingPaymentMethod:
      return 'Failed to save your payment method. Please try again.'
    case ErrorCode.failedCreateSubscription:
      return 'Failed to create subscription. Please try again.'
    case ErrorCode.shippingAddressMissing:
      return 'Please add shipping address information.'
    case ErrorCode.billingAddressMissing:
      return 'Please add billing address information.'
    case ErrorCode.addressNotSupported:
      return 'Your address is not supported yet.'
    case ErrorCode.invalidEmailFormat:
      return 'Email has invalid format.'
    case ErrorCode.errorVerifying2faCode:
      return 'Verification code not found or expired.'
    case ErrorCode.error2faCodeNotValid:
      return 'Wrong code. Try again.'
    case ErrorCode.missingPhoneNumberForLogin:
      return 'Your account does not have a phone number associated, so you can\'t verify via SMS'
    case ErrorCode.failLogin:
      return 'Email or password invalid.'
    case ErrorCode.cardDeclined:
      return 'Your card was declined. Please try a different payment method.'
    case ErrorCode.failedPurchase:
      return 'Purchase failed. Please try again.'
    case ErrorCode.errorRequesting2faCode:
      return 'Error requesting 2FA code. Please try again.'
    case ErrorCode.wrongEmailOrPassword:
      return 'Could not find account with the given email or password is not correct.'
    case ErrorCode.tooManyLoginAttempts:
      return 'Too many login attempts, please wait a bit before trying again'
    case ErrorCode.verifyTwoFactorFailed:
      return 'Failed verifying 2FA code. Please try again.'
    case ErrorCode.invalidTwoFactorCode:
      return 'The provided 2FA code is invalid. Please try again.'
    case ErrorCode.invalidPhoneNumber:
      return 'Invalid phone number provided. Please try again.'
    case ErrorCode.phoneNumberAlreadyUsed:
      return 'The provided phone number is already in use.'
    case ErrorCode.missingVerificationCode:
      return 'You did not provide a verification code'
    case ErrorCode.failedFetchPaymentMethods:
      return 'Failed to load existing payment methods. Please try again.'
    case ErrorCode.invalidCvc:
      return 'The CVC code you provided is invalid. Please make sure you input the correct value.'
    case ErrorCode.invalidAddress:
      return 'The CVC code you provided is invalid. Please make sure you input the correct value.'
    case ErrorCode.failedAddCartData:
      return 'Failed to add address data to cart. Please try again.'
    default:
      return 'Unknown error occured. Please try again.'
  }
}